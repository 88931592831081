import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { useCurrentAccount } from "../../../hooks/useCurrentAccount";
import { notifications } from "../../../helpers/notifications";
import { useAvatar } from "../../../hooks/useAvatar";

const ProfileMenu = () => {
  const [menu, setMenu] = useState(false);
  const { foto } = useAvatar();
  const { instance } = useMsal();
  const { userName } = useCurrentAccount();

  const handleLogout = () => {
    instance.logoutRedirect().catch((e) => {
      notifications({ ex: e });
    });
  };

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={foto}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{userName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="dropdown-divider" />
          <DropdownItem
            tag="a"
            className="dropdown-item"
            onClick={handleLogout}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Salir</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ProfileMenu;
