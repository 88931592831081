import { useEffect } from "react";
import PropTypes from "prop-types";

//actions
import { changeLayout } from "../../store/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import Header from "./Header";
import Footer from "./Footer";
import { useLocation } from "react-router";
import Navbar from "./Navbar";
import { SET_NAVBARBUTTONVISIBLE } from "../../store/layout/actionTypes";
import { RootState } from "../../store";

const HorizontalLayout = ({ children }: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const entornoPROD = process.env.REACT_APP_PRODUCTION === "true";
  const { navbarButtonVisible } = useSelector(
    (state: RootState) => state.Layout
  );

  useEffect(() => {
    const checkNavbarButton = () => {
      const navbarButton = document.getElementById("navbarButton");
      if (navbarButton) {
        const display = window.getComputedStyle(navbarButton).display;
        dispatch({
          type: SET_NAVBARBUTTONVISIBLE,
          payload: display !== "none",
        });
      }
    };

    // Ejecuta al montar y cada vez que cambia el tamaño de la ventana.
    checkNavbarButton();
    window.addEventListener("resize", checkNavbarButton);

    // Quita el detector de eventos al desmontar.
    return () => window.removeEventListener("resize", checkNavbarButton);
  }, [dispatch]);

  /*
  document title
  */
  useEffect(() => {
    const title = location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = currentage + " | Explorer Travel System";
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout("horizontal"));
  }, [dispatch]);

  return (
    <div id="layout-wrapper">
      <Header />
      {/*Navbar que se ejecuta si es ambiente de pruebas*/}
      {(!entornoPROD || navbarButtonVisible) && <Navbar />}
      <div className="main-content">{children}</div>
      <Footer />
    </div>
  );
};

HorizontalLayout.propTypes = {
  children: PropTypes.object,
};

export default HorizontalLayout;
