import { all, fork, select, takeEvery, call, put } from "redux-saga/effects";
import {
    BOOKING_CAMBIAR_ESTADO_BOOKING,
    BOOKING_CARGAR_DATA,
    BOOKING_FACTURAR_BOOKING,
    GET_BOOKINGS_RESERVAS,
    LOADING_BOOKINGS_RESERVAS,
    SET_BOOKINGS_RESERVAS
} from "./actionsTypes";
import { get, post, put as putBooking } from "../../../helpers/api_helper";
import { notifications } from "../../../helpers/notifications";
import { apiActividadMaestra, apiBookings } from "../../../constants/api";
import { Booking as IBooking, BookingsState, CambioEstadoBooking } from "../../../interfaces/booking";
import moment from "moment-timezone";
import { setCurrentBooking, setData, setLoading, setModalFacturacion } from "./actions";
import { RootState } from "../../index";
import { toODataString } from "@progress/kendo-data-query";
import { StateCatalogos } from "../../catalogos/CatalogosReducer";
import { eliminarFiltroEspecial, filtroEspecial, fusionarFiltros } from "../../../helpers/utils";
import { getBookingReservas } from "../../../helpers/odataApiServices";
import { Estado } from "../../../interfaces/estado";

const Booking = (state: RootState) => state.Booking;
const Catalogos = (state: RootState) => state.Catalogos;

const getBookings = (next: string) => {
    return get(`${apiBookings}&${next}`)
        .then(res => {
            return {
                data: res.value.map((b: IBooking) => {
                    b.FechaInicio = moment(b.FechaInicio).toDate();
                    b.FechaFin = moment(b.FechaFin).toDate();
                    b.ClienteNames = `${b.Cliente.Nombre}`;
                    return b;
                }),
                total: res['@odata.count']
            }
        }
        ).catch(error => {
            notifications({ ex: error });
        });
}

const getActiviadMaestraByBookingCode = (BookingCode: string) => {
    return get(`${apiActividadMaestra}&&$filter=BookingCode eq '${BookingCode}'`)
        .then(res => {
            return res.value[0].ActividadMaestra;
        }
        ).catch(error => {
            notifications({ ex: error });
        });
}

function* getBookingsByPage(next: string): any {
    yield put(setLoading(true));
    const bookings = yield call(getBookings, next);
    yield put(setData(bookings));
    yield put(setLoading(false));
}

function* actualizarEstadoBooking(booking: IBooking, estadoNuevo: Estado): any {
    let actividadMaestra = yield call(getActiviadMaestraByBookingCode, booking.BookingCode);
    actividadMaestra.EstadoId = estadoNuevo.EstadoId;
    let estadoAntiguo = booking.EstadoId;
    booking.EstadoId = estadoNuevo.EstadoId;
    booking.Estado = estadoNuevo;
    try {
        yield call(putBooking, `Bookings(${booking.BookingId})`, estadoNuevo.Nombre !== "Operación" ? booking : {
            ...booking,
            FechaEnvioFacturacion: null
        });
        yield call(putBooking, `ActividadesMaestras(${actividadMaestra.ActividadMaestraId})`, actividadMaestra);
        let newCambioEstadoCerrar: CambioEstadoBooking = {
            BookingId: booking.BookingId,
            EstadoAntiguoId: estadoAntiguo,
            EstadoNuevoId: estadoNuevo.EstadoId,
            FechaCambio: new Date(),
        };
        yield call(post, `CambiosEstadoBooking`, newCambioEstadoCerrar);
        yield put(setCurrentBooking(booking));
        notifications({ title: 'Operación exitosa', message: 'Booking actualizado', toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
    }
}

function* actualizarEstadoBookingFacturar(booking: IBooking, estadoFacturar: Estado): any {
    let bookingFacturar = { ...booking };
    let estadoAntiguo = bookingFacturar.EstadoId;
    bookingFacturar.EstadoId = estadoFacturar.EstadoId;
    bookingFacturar.Estado = estadoFacturar;
    try {
        yield put(setModalFacturacion(true));
        yield call(post, `GenerarOrdenesFacturacion(${bookingFacturar.BookingId},Factura,0)`, '');
        yield put(setModalFacturacion(false));
        let newCambioEstadoFacturar: CambioEstadoBooking = {
            BookingId: bookingFacturar.BookingId,
            EstadoAntiguoId: estadoAntiguo,
            EstadoNuevoId: estadoFacturar.EstadoId,
            FechaCambio: new Date(),
        };
        yield call(post, `CambiosEstadoBooking`, newCambioEstadoFacturar);
        yield put(setCurrentBooking(bookingFacturar));
        notifications({
            title: `Operación exitosa`,
            message: `El booking con código: ${bookingFacturar.BookingCode} se ha enviado a facturación`,
            toastType: 'success',
            seRegistra: true,
        });
    } catch (error) {
        notifications({ ex: error });
        yield put(setModalFacturacion(false));
    }
}

function* getBookingsData() {
    const { dataState }: BookingsState = yield select(Booking);
    const filtroEOperativo = filtroEspecial(dataState); // buscar EOperativo y construir filtro
    let dataStateSinEOperativo = eliminarFiltroEspecial(dataState); // busca y elimina
    let dataStringSinEOperativo = toODataString(dataStateSinEOperativo);
    let dataFusion = fusionarFiltros(dataStringSinEOperativo, filtroEOperativo); // fusiona filtros
    yield fork(getBookingsByPage, dataFusion);
}

function* cambioEstadoBooking(action: { type: string, payload: number }) {
    const { selectedBooking }: BookingsState = yield select(Booking);
    const { estados }: StateCatalogos = yield select(Catalogos);
    const nuevoEstado = estados["Booking"][action.payload];
    yield fork(actualizarEstadoBooking, selectedBooking!, nuevoEstado);
}

function* facturarBooking() {
    const { selectedBooking }: BookingsState = yield select(Booking);
    const { estados }: StateCatalogos = yield select(Catalogos);
    const estadoFacturar = estados["Booking"]["Enviado a facturar"];
    yield fork(actualizarEstadoBookingFacturar, selectedBooking!, estadoFacturar);
}

function* getBookingsReservas(): any {
    yield put({ type: LOADING_BOOKINGS_RESERVAS, payload: true });
    const bookingsR = yield call(getBookingReservas);
    yield put({ type: SET_BOOKINGS_RESERVAS, payload: bookingsR });
    yield put({ type: LOADING_BOOKINGS_RESERVAS, payload: false });
}

function* watchCargarBookings() {
    yield takeEvery(BOOKING_CARGAR_DATA, getBookingsData);
}

function* watchCambioEstadoBooking() {
    yield takeEvery(BOOKING_CAMBIAR_ESTADO_BOOKING, cambioEstadoBooking);
}

function* watchFacturarBooking() {
    yield takeEvery(BOOKING_FACTURAR_BOOKING, facturarBooking);
}


function* watchGetBookingsReservas() {
    yield takeEvery(GET_BOOKINGS_RESERVAS, getBookingsReservas);
}

function* bookingSaga() {
    yield all([
        fork(watchCargarBookings),
        fork(watchCambioEstadoBooking),
        fork(watchFacturarBooking),
        fork(watchGetBookingsReservas),
    ])
}

export default bookingSaga;