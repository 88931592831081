import {NavLink} from "react-router-dom"
import classname from "classnames";
import {useCallback, useEffect, useState} from "react";
import {get} from "../../helpers/api_helper";
import {handlePermisosCarpeta, hasRole} from "../../helpers/utils";
import {toggleLeftmenu} from "../../store/layout/actions";
import {useDispatch} from "react-redux";
import {notifications} from "../../helpers/notifications";

interface ItemReporte {
    nombre: string;
    nombreMostrar: string;
    EsCarpeta: boolean;
    Archivos: any[];
    accesos: string[];
}

export const NavegacionReportes = ({roles, isOpen, leftMenu, setIsOpenReportes}: {
    roles: string[],
    isOpen: boolean,
    leftMenu: boolean,
    setIsOpenReportes: (isOpen: boolean) => void
}) => {
    const dispatch = useDispatch();
    const [reportes, setreportes] = useState<any[]>([]);
    const [renderReport, setrenderReport] = useState(true);
    const navbarButton = document.getElementById('navbarButton');


    useEffect(() => {
        if (renderReport) {
            requestReport();
        }
    }, []);

    const requestReport = useCallback(() => {
        get('Reports/GetUserReports').then(respuesta => {
            respuesta.push({
                "nombre": "Auditoria",
                "nombreNavegacion": 'ss',
                "nombreMostrar": "Auditoria",
                "esCarpeta": true,
                "archivos": [],
                "isOpen": false,
            })
            setreportes(respuesta.map((item: ItemReporte) => {
                return {...item, accesos: handlePermisosCarpeta(item.nombre).accesos, isOpen: false}
            }));
            setrenderReport(false);
        }).catch(error => {
            notifications({ex: error});
        });
    }, []);


    const printMenu = (listaReportes: any[], userRoles: string[]) => {
        const items = [];

        for (let reporte of listaReportes) {
            const navegacion = "/visor-reportes/" + reporte.nombreNavegacion;
            let element;
            if (hasRole(userRoles, reporte.accesos)) {
                if (reporte.esCarpeta) {
                    element = getFolderMenu(reporte);
                } else {
                    element = <NavLink key={reporte.nombre} className="dropdown-item" to={navegacion}>
                        <i key={reporte.nombre} className="k-icon k-i-aggregate-fields me-2"/>
                        {reporte.nombreMostrar}
                    </NavLink>;
                }
                items.push(element);
            }
        }
        return items;
    };

    const toggleOpen = (nombre: string) => {
        setreportes(reportes.map(reporte =>
            reporte.nombre === nombre ? {...reporte, isOpen: !reporte.isOpen} : {...reporte, isOpen: false}
        ));
    };

    const getFolderMenu = (carpeta: any) => {
        return (
            <div key={carpeta.nombre} className="dropdown">
                <NavLink
                    key={carpeta.nombre}
                    to={carpeta.nombre === 'Auditoria' ? '/auditoria' : '/888'}
                    className="dropdown-item dropdown-toggle arrow-none"
                    onClick={e => {
                        if (carpeta.nombre !== 'Auditoria') {
                            e.preventDefault();
                            if (window.getComputedStyle(navbarButton!).display !== 'none'){toggleOpen(carpeta.nombre);}
                        }
                    }}
                >
                    {carpeta.nombre}
                    {carpeta.nombre !== 'Auditoria' && <div className="arrow-down"/>}
                </NavLink>
                {carpeta.nombre !== 'Auditoria' && (
                    <div className={classname("dropdown-menu", {show: carpeta.isOpen})}>
                        {getFolderMenuItems(carpeta.nombre, carpeta.archivos)}
                    </div>
                )}
            </div>
        );
    };
    const getFolderMenuItems = (carpetaNombre: string, itemsLinks: any[]) => {
        const items = [];
        for (let itemLink of itemsLinks) {
            if (!itemLink.esCarpeta) {
                const navegacion = '/visor-reportes/' + carpetaNombre + '/' + itemLink.nombreNavegacion;
                const element = <NavLink onClick={() => {
                    setIsOpenReportes(false)
                    toggleOpen('nombreCarpetaNoExistente') // Cerrar todas las carpetas

                    if (window.getComputedStyle(navbarButton!).display !== 'none') {
                        dispatch(toggleLeftmenu(!leftMenu));
                    }
                }} key={itemLink.nombre}
                                         to={navegacion} className="dropdown-item">
                    {itemLink.nombreMostrar}
                </NavLink>;
                items.push(element);
            } else {
                const element = getFolderMenu(itemLink);
                items.push(element);
            }
        }
        return items;
    };
    return (
        <div className={classname("dropdown-menu", {show: isOpen})}>
            {printMenu(reportes, roles)}
        </div>
    )
};

export default NavegacionReportes;