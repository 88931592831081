import { LogLevel } from "@azure/msal-browser";

const REACT_APP_PRODUCTION = process.env.REACT_APP_PRODUCTION;

if (!process.env.REACT_APP_CLIENT_ID) {
  throw new Error('El CLIENT_ID no está definido en las variables de entorno');
}

const loggerCallback = (level: any, message: any, containsPii: any) => {
  if (containsPii) {
    return;
  }
  if (!REACT_APP_PRODUCTION) {
    switch (level) {
      case LogLevel.Error:
        console.error("msal-Error->", message);
        return;
      case LogLevel.Info:
        console.info("msal-Info->", message);
        return;
      case LogLevel.Verbose:
        console.debug("msal-Verbose->", message);
        return;
      case LogLevel.Warning:
        console.warn("msal-Warning->", message);
        return;
    }
  }
};

const system = {
  loggerOptions: {
    loggerCallback,
    logLevel: LogLevel.Verbose,
    piiLoginEnabled: false,
  },
};
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/2a967794-c4d1-4bc2-8036-1a5201a5d767",
    redirectUri: "/",
    postLogoutRedirectUri: "/login",
    navigateToLoginRequestUrl: false,
    tokenRefreshUri: window.location.origin + "/refresh",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system,
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const protectedResources = {
  apiPerseverance: {
    scopes: [`api://${process.env.REACT_APP_SCOPE_XTS_ID_BACKEND}/.default`],
  },
};

export const loginRequest = {
  scopes: [...protectedResources.apiPerseverance.scopes],
};
