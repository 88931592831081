import { Action } from "../../interfaces/general";
import { ListaSeleccion } from "../../interfaces/listaSeleccion";
import { CAT_EDIT_LISTA_SELECCION, CAT_ELIMINAR_LS, CAT_OPEN_EDITING, CAT_OPEN_ELIMINAR, CAT_SET_AMBITO_SELECTED, CAT_SET_CURRENT_LS, CAT_SET_PROCESS_EDIT, CAT_SET_PROCESS_ELIMINAR } from "./CatalogosTypes";


interface CatalogoListaSeleccionState {
    currentCatalogoLS: ListaSeleccion;
    ambitoSelected: string;
    openEliminar: boolean;
    isDelete: boolean;
    openEditing: boolean;
    processEdit: boolean;
}

const initialState: CatalogoListaSeleccionState = {
    currentCatalogoLS: {} as ListaSeleccion,
    ambitoSelected: '',
    openEliminar: false,
    isDelete: false,
    openEditing: false,
    processEdit: false,
}

const actions: Action<any> = {
    type: '',
    payload: ''
}

export const CatalogoListaSeleccionReducer = (state = initialState, { type, payload } = actions) => {
    switch (type) {
        case CAT_SET_CURRENT_LS:
            return {
                ...state,
                currentCatalogoLS: payload

            }
        case CAT_SET_AMBITO_SELECTED:
            return {
                ...state,
                ambitoSelected: payload
            }
        case CAT_OPEN_ELIMINAR:
            return {
                ...state,
                openEliminar: payload
            }
        case CAT_OPEN_EDITING:
            return {
                ...state,
                openEditing: payload
            }
        case CAT_SET_PROCESS_ELIMINAR:
            return {
                ...state,
                isDelete: payload
            }
        case CAT_ELIMINAR_LS:
            return {
                ...state
            }
        case CAT_EDIT_LISTA_SELECCION: {
            return {
                ...state,
                processEdit: true
            }
        }
        case CAT_SET_PROCESS_EDIT: {
            return {
                ...state,
                processEdit: payload
            }
        }
        default:
            return state;
    }
}


export default CatalogoListaSeleccionReducer;