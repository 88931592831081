import { Action } from "../../../interfaces/general";
import {
    RESERVAS_DETALLES_CHANGE_ESTADO_DELETE,
    RESERVAS_DETALLES_CHANGE_ESTADO_SET,
    RESERVAS_DETALLES_CHANGE_ESTADO_UPDATE,
    RESERVAS_DETALLES_CLEAR,
    RESERVAS_DETALLES_CONFIRM_LOADING,
    RESERVAS_DETALLES_LOADING,
    RESERVAS_DETALLES_SET,
    RESERVAS_DETALLES_SET_SELECTED,
    RESERVAS_DETALLES_UPDATE_COMENTARIO
} from './types';
import { ReservaDetalle } from '../../../interfaces/reservasDetalle';
import { ReservaDetallesChangeSend } from '../../../interfaces/sends';

export interface StateReservaDetalle {
    data: ReservaDetalle[];
    loading: boolean;
    changeDetalle: ReservaDetallesChangeSend[];
    selected: ReservaDetalle;
    processConfirmLoading: boolean;
}
const INIT_STATE: StateReservaDetalle = {
    data: [],
    loading: false,
    changeDetalle: [],
    selected: {} as ReservaDetalle,
    processConfirmLoading: false
}

const action: Action<boolean | ReservaDetalle[] | any> = {
    type: '',
    payload: false
}

const reservasDetalles = (state = INIT_STATE, { type, payload } = action): StateReservaDetalle => {
    switch (type) {
        case RESERVAS_DETALLES_SET:
            return {
                ...state,
                data: payload
            }
        case RESERVAS_DETALLES_CLEAR:
            return {
                ...state,
                data: [],
                changeDetalle: [],
                selected: {} as ReservaDetalle
            }
        case RESERVAS_DETALLES_CHANGE_ESTADO_UPDATE:
            return {
                ...state,
                changeDetalle: state.changeDetalle.map(c => c.reservaDetalleId === payload.reservaDetalleId
                    ? payload
                    : c)
            }
        case RESERVAS_DETALLES_CHANGE_ESTADO_SET:
            return {
                ...state,
                changeDetalle: payload
            }
        case RESERVAS_DETALLES_CHANGE_ESTADO_DELETE:
            return {
                ...state,
                changeDetalle: state.changeDetalle.filter(item => item.reservaDetalleId !== payload)
            }
        case RESERVAS_DETALLES_SET_SELECTED:
            return {
                ...state,
                selected: payload
            }
        case RESERVAS_DETALLES_UPDATE_COMENTARIO:
            return {
                ...state,
                data: state.data.map(rd => (rd.ReservaDetalleId === payload.ReservaDetalleId
                    ? { ...rd, Comentario: payload.Comentario }
                    : { ...rd }))
            }
        case RESERVAS_DETALLES_LOADING:
            return {
                ...state,
                loading: payload
            }
        case RESERVAS_DETALLES_CONFIRM_LOADING:
            return {
                ...state,
                processConfirmLoading: payload
            }
        default:
            return state
    }
}

export default reservasDetalles;