
export const ACTIVIDAD_RESERVA_SET_DATA = 'ACTIVIDAD_RESERVA_SET_DATA';

export const ACTIVIDAD_RESERVA_SET_SELECTED = "ACTIVIDAD_RESERVA_SET_SELECTED";
export const ACTIVIDAD_RESERVA_UPDATE_SELECTED = "ACTIVIDAD_RESERVA_UPDATE_SELECTED";
export const ACTIVIDAD_RESERVA_ADD_MULTISELECT = "ACTIVIDAD_RESERVA_ADD_MULTISELECT";
export const ACTIVIDAD_RESERVA_CLEAR_MULTISELECT = "ACTIVIDAD_RESERVA_CLEAR_MULTISELECT";

export const ACTIVIDAD_RESERVA_START_GENERATED = "ACTIVIDAD_RESERVA_START_GENERATED";
export const ACTIVIDAD_RESERVA_ALL_GENERATED = "ACTIVIDAD_RESERVA_ALL_GENERATED";

export const ACTIVIDAD_RESERVA_SET_START = "ACTIVIDAD_RESERVA_SET_START";
export const ACTIVIDAD_RESERVA_GET = "ACTIVIDAD_RESERVA_GET";
export const ACTIVIDAD_RESERVA_LOADING = "ACTIVIDAD_RESERVA_LOADING";

export const ACTIVIDAD_RESERVA_SEARCH = "ACTIVIDAD_RESERVA_SEARCH";
export const ACTIVIDAD_RESERVA_IS_SEARCH = "ACTIVIDAD_RESERVA_IS_SEARCH";

export const ACTIVIDAD_RESERVA_CLEAR = "ACTIVIDAD_RESERVA_CLEAR";

export const ACTIVIDAD_RESERVA_UPDATE_BLOQUEO = "ACTIVIDAD_RESERVA_UPDATE_BLOQUEO";

export const ACTIVIDAD_RESERVA_SET_SEARCH_TERM = "ACTIVIDAD_RESERVA_SET_SEARCH_TERM";
export const ACTIVIDAD_RESERVA_SET_SEARCH_TERM_RESET = "ACTIVIDAD_RESERVA_SET_SEARCH_TERM_RESET";

export const HYDRATE_ACTIVIDAD_RESERVA = "HYDRATE_ACTIVIDAD_RESERVA";