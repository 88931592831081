import { useCallback, useEffect, useState } from "react";
import { sinFoto } from "../constants/default";
import * as graph from "@microsoft/microsoft-graph-client";
import AuthHeader from '../helpers/authHeader';

export const useAvatar = () => {
    const [foto, setFoto] = useState<any>(sinFoto);

    const getFoto = useCallback(async () => {
        const accessToken = (await AuthHeader()).accessRokenGraph;
        const client = graph.Client.init({
            authProvider: (done) => {
                done(null, accessToken)
            }
        });
        const result = await client.api('me/photos/48x48/$value')
            .responseType(graph.ResponseType.BLOB)
            .get();
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = () => {
            const base = reader.result;
            setFoto(base);
        }
    }, []);

    useEffect(() => {
        getFoto();
    }, [getFoto])

    return {
        foto
    }
}