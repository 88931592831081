import {Action} from "../../interfaces/general";
import {
    DECKPLAN_LOAD_DATA,
    DECKPLAN_OPEN_FORM_LOCK,
    DECKPLAN_SET_BLOQUEOS,
    DECKPLAN_SET_CABINAS,
    DECKPLAN_SET_DATA_ACOMODACIONES,
    DECKPLAN_SET_DATA_ASIGNAR,
    DECKPLAN_SET_DATA_MODAL_CABINA,
    DECKPLAN_SET_DATA_SALIDA, DECKPLAN_SET_DISPONIBILIDAD, DECKPLAN_SET_LOADING,
    DECKPLAN_SET_MODAL_CABINAS_RESTANTES,
    DECKPLAN_SET_MODAL_DESASIGNAR_TODO,
    DECKPLAN_SET_MODAL_YIELD,
    DECKPLAN_SET_NUMERO_TIPO_CABINA,
    DECKPLAN_SET_PASAJEROS_ASIGNADOS,
    DECKPLAN_SET_PASAJEROS_LISTA,
    DECKPLAN_SET_VISIBLE
} from "./actionsTypes";
import {
    DataAsignar,
    ISalidaEmbarcacion,
    LoadDeckplanData,
    ModalData,
    StateDeckplan
} from "../../interfaces/deckplan";

const INIT_STATE: StateDeckplan = {
    pasajerosLista: [],
    initialData: {} as LoadDeckplanData,
    cabinas: [],
    visible: false,
    openFormLock: false,
    numeroTipoCabina: 0,
    datosSalida: {} as ISalidaEmbarcacion,
    pasajerosAsignados: [],
    modalYield: false,
    bloqueos: [],
    modalCabina: {} as ModalData,
    dataAsignar: {} as DataAsignar,
    dataAcomodaciones: [],
    modalDesasignarTodo: false,
    modalCabinasRestantes: false,
    loading: false,
    disponibilidad: []
}
const action: Action<any> = {
    type: '',
    payload: ''
}
export const DeckplanReduce = (state = INIT_STATE, {type, payload} = action): StateDeckplan => {
    switch (type) {
        case DECKPLAN_LOAD_DATA:
            return {
                ...state,
                initialData: payload,
            }
        case DECKPLAN_SET_PASAJEROS_LISTA:
            return {
                ...state,
                pasajerosLista: payload,
            }
        case DECKPLAN_SET_CABINAS:
            return {
                ...state,
                cabinas: payload,
            }
        case DECKPLAN_SET_VISIBLE:
            return {
                ...state,
                visible: payload,
            }
        case DECKPLAN_OPEN_FORM_LOCK:
            return {
                ...state,
                openFormLock: payload,
            }
        case DECKPLAN_SET_NUMERO_TIPO_CABINA:
            return {
                ...state,
                numeroTipoCabina: payload,
            }
        case DECKPLAN_SET_DATA_SALIDA:
            return {
                ...state,
                datosSalida: payload,
            }
        case DECKPLAN_SET_PASAJEROS_ASIGNADOS:
            return {
                ...state,
                pasajerosAsignados: payload,
            }
        case DECKPLAN_SET_MODAL_YIELD:
            return {
                ...state,
                modalYield: payload,
            }
        case DECKPLAN_SET_BLOQUEOS:
            return {
                ...state,
                bloqueos: payload,
            }
        case DECKPLAN_SET_DATA_MODAL_CABINA:
            return {
                ...state,
                modalCabina: payload,
            }
        case DECKPLAN_SET_DATA_ASIGNAR:
            return {
                ...state,
                dataAsignar: payload,
            }
        case DECKPLAN_SET_DATA_ACOMODACIONES:
            return {
                ...state,
                dataAcomodaciones: payload,
            }
        case DECKPLAN_SET_MODAL_DESASIGNAR_TODO:
            return {
                ...state,
                modalDesasignarTodo: payload,
            }
        case DECKPLAN_SET_MODAL_CABINAS_RESTANTES:
            return {
                ...state,
                modalCabinasRestantes: payload,
            }
        case DECKPLAN_SET_LOADING:
            return {
                ...state,
                loading: payload,
            }
        case DECKPLAN_SET_DISPONIBILIDAD:
            return {
                ...state,
                disponibilidad: payload,
            }
        default:
            return {
                ...state
            }
    }
}
export default DeckplanReduce;