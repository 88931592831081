export const BOOKING_SET_DATASTATE = "BOOKING_SET_DATASTATE";
export const BOOKING_SET_CURRENT_BOOKING = "BOOKING_SET_CURRENT_BOOKING";
export const BOOKING_SET_DATA = "BOOKING_SET_DATA";
export const BOOKING_SET_MODAL_FACTURACION = "BOOKING_SET_MODAL_FACTURACION";
export const BOOKING_SET_MODAL_NOTA_CREDITO = "BOOKING_SET_MODAL_NOTA_CREDITO";
export const BOOKING_CARGAR_DATA = "BOOKING_CARGAR_DATA";
export const BOOKING_SET_LOADING = "BOOKING_SET_LOADING";
export const BOOKING_SET_MODAL_CONFIRMACION = "BOOKING_SET_MODAL_CONFIRMACION";
export const BOOKING_CAMBIAR_ESTADO_BOOKING = "BOOKING_CAMBIAR_ESTADO_BOOKING";
export const BOOKING_FACTURAR_BOOKING = "BOOKING_FACTURAR_BOOKING";
export const LOADING_BOOKINGS_RESERVAS = "LOADING_BOOKINGS_RESERVAS";
export const GET_BOOKINGS_RESERVAS = "GET_BOOKINGS_RESERVAS";
export const SET_BOOKINGS_RESERVAS = "SET_BOOKINGS_RESERVAS";
export const SET_SELECT_BOOKING_RESERVAS = "SET_SELECT_BOOKING_RESERVAS";
