import {del, get, post, put} from "./api_helper";
import {useEffect, useState} from "react";
import {notifications} from "./notifications";

export const UseGetAll = (url: string) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        get(url).then(odata => {
            setData(odata.value)
        });
    }, [url]);
    return data
}


export const UseGetBloqueosPromise = (url: string, body: any) => {
    return post(url, body).then(json => {
        return json
    });
}

export const UseGetOnly = (url: string) => {
    const [dataDetalle, setDataDetalle] = useState([]);
    useEffect(() => {
        get(url).then(odata => {
            setDataDetalle(odata.value[0])
        });
    }, [url]);
    return {dataDetalle}
}

export const UseGetOdata = async (url: string) => {
    return get(url).then(odata => {
        return odata.value
    }).catch(async error => notifications({
        ex: error
    }));
}

export const addItem = (url: string, data: any, message: string, title: string, refresh: string) => {
    return post(url, data).then(async (_resp) => {
        const stateModal = false
        const dataRefresh = await UseGetOdata(refresh)
        notifications({title: `${title}`, message: `${message}`});
        return {stateModal, dataRefresh}
    })
        .catch(async error => {
            notifications({ex: error});
            const stateModal = false
            const dataRefresh = await UseGetOdata(refresh)
            return {stateModal, dataRefresh}
        });
}


export const Update = (url: string, data: any) => {
    return put(`${url}`, data).then(_resp => {
        notifications({title: 'Registro actualizado', message: 'Se actualizó correctamente'});
        return true
    }).catch(error => notifications({
        ex: error
    }));
}


export const DeleteAll = (url: string, mensaje: string) => {
    return del(`${url}`).then(_resp => {
        notifications({message: `${mensaje}`, title: `Eliminado`});
        return true
    }).catch(error => {
        notifications({ex: error});
        return true
    });
}
export const DeleteItem = (url: string, mensaje: string, refresh: string) => {
    return del(`${url}`).then(_resp => {
        notifications({message: `${mensaje}`, title: `Eliminado`});
        return UseGetOdata(refresh)
    }).catch(error => {
        notifications({ex: error});
        return UseGetOdata(refresh)
    });
}

export const addNewItem = (url: string, data: any) => {
    return post(url, data).then((_resp) => {
        notifications({title: `Operación exitosa`, message: 'Se ingresó correctamente'});
        return true
    }).catch(error => {
        notifications({ex: error});
        return true
    });
}





