import { Store } from "redux";
import { StateActividadReserva } from "../ActividadesReservas";
import { HYDRATE_ACTIVIDAD_RESERVA } from "../types";

const channel = new BroadcastChannel('reservas_actividad_sync_channel');

interface ChannelMessage {
    type: string;  // Tipo de mensaje (por ejemplo, "CLICK_EVENT")
    payload: StateActividadReserva;  // Los datos que quieres enviar
  }

export const syncActividadWithOtherTabs = (store: Store):void =>{

    let prevState:StateActividadReserva = store.getState().ActividadesReservas; 

    channel.onmessage = (event: MessageEvent<ChannelMessage>) => {
        const { type,payload } = event.data;
        store.dispatch({ type, payload });
    };

    store.subscribe(() => {
        const  currenteState:StateActividadReserva  = store.getState().ActividadesReservas;
        if(Object.keys(prevState.selected).length > 0 && Object.keys(currenteState.selected).length > 0 ){
        if(prevState.selected.bloqueoReserva === false && currenteState.selected.bloqueoReserva === true){

            const message= 
            {
                type:HYDRATE_ACTIVIDAD_RESERVA,
                payload:currenteState
            };
            channel.postMessage(message);
        }
    }

        prevState = currenteState;
    });
}