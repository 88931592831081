import {Col, Container, Row} from "reactstrap"

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Metropolitan Touring</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Explorer Travel System
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
