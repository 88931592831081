import { Action } from "../../../interfaces/general"
import { ActividadReserva } from '../../../interfaces/respuestas';
import {
    ACTIVIDAD_RESERVA_CLEAR,
    ACTIVIDAD_RESERVA_LOADING,
    ACTIVIDAD_RESERVA_SET_DATA,
    ACTIVIDAD_RESERVA_SET_SELECTED,
    ACTIVIDAD_RESERVA_SET_START,
    ACTIVIDAD_RESERVA_IS_SEARCH,
    ACTIVIDAD_RESERVA_CLEAR_MULTISELECT,
    ACTIVIDAD_RESERVA_ADD_MULTISELECT,
    ACTIVIDAD_RESERVA_UPDATE_BLOQUEO,
    ACTIVIDAD_RESERVA_SET_SEARCH_TERM,
    ACTIVIDAD_RESERVA_SET_SEARCH_TERM_RESET,
    HYDRATE_ACTIVIDAD_RESERVA,
    ACTIVIDAD_RESERVA_UPDATE_SELECTED
} from './types';

export interface StateActividadReserva {
    data: ActividadReserva[];
    loading: boolean;
    selected: ActividadReserva;
    multiSelected: ActividadReserva[];
    isGenerated: boolean;
    isSearch: boolean;
    searchTerm: string;
}

const INITIAL_STATE: StateActividadReserva = {
    data: [],
    loading: false,
    selected: {} as ActividadReserva,
    multiSelected: [],
    isGenerated: false,
    isSearch: false,
    searchTerm: '',
}

const actions: Action<ActividadReserva[] | boolean | ActividadReserva | any> = {
    type: '', payload: []
}

const ActividadesReservas = (state = INITIAL_STATE, { type, payload } = actions): StateActividadReserva => {
    switch (type) {
        case ACTIVIDAD_RESERVA_LOADING:
            return {
                ...state,
                loading: payload
            }
        case ACTIVIDAD_RESERVA_SET_DATA:
            return {
                ...state,
                data: payload
            }
        case ACTIVIDAD_RESERVA_SET_SELECTED:
            return {
                ...state,
                selected: payload
            }
        case ACTIVIDAD_RESERVA_UPDATE_SELECTED:
            return {
                ...state,
                selected: { ...state.selected, ...payload}
            }
        case ACTIVIDAD_RESERVA_SET_START:
            return {
                ...state,
                isGenerated: payload
            }
        case ACTIVIDAD_RESERVA_CLEAR:
            return {
                ...state,
                data: [],
                selected: {} as ActividadReserva
            }
        case ACTIVIDAD_RESERVA_IS_SEARCH:
            return {
                ...state,
                isSearch: payload
            }
        case ACTIVIDAD_RESERVA_CLEAR_MULTISELECT:
            return {
                ...state,
                multiSelected: []
            }
        case ACTIVIDAD_RESERVA_ADD_MULTISELECT:
            return {
                ...state,
                multiSelected: payload
            }
        case ACTIVIDAD_RESERVA_UPDATE_BLOQUEO:
            return {
                ...state,
                data: state.data.map(item =>
                ((item.actividadReservaCabeceraId === payload.actividadReservaCabeceraId &&
                    item.actividadId === payload.actividadId) ? { ...item, bloqueoReserva: true } : item))
            }
        case ACTIVIDAD_RESERVA_SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: payload
            }
        case ACTIVIDAD_RESERVA_SET_SEARCH_TERM_RESET:
            return {
                ...state,
                searchTerm: ""
            }
        case HYDRATE_ACTIVIDAD_RESERVA:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}

export default ActividadesReservas;