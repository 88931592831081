import { ACTIVIDAD_RESERVA_DETALLE_CLEAR, ACTIVIDAD_RESERVA_DETALLE_SET_DATA, ACTIVIDAD_RESERVA_DETALLE_LOADING } from './types';
import { Action } from '../../../interfaces/general';
import { ActividadReservaDetalle } from '../../../interfaces/respuestas';

interface StateActividadReservaDetalles {
    data: ActividadReservaDetalle[],
    loading: boolean
}

const INITIAL_STATE: StateActividadReservaDetalles = {
    data: [],
    loading: false
}

const actions: Action<any> = {
    type: '',
    payload: ''
}

const ActividadReservaDetalles = (state = INITIAL_STATE, { type, payload } = actions): StateActividadReservaDetalles => {
    switch (type) {
        case ACTIVIDAD_RESERVA_DETALLE_SET_DATA:
            return {
                ...state,
                data: payload
            }
        case ACTIVIDAD_RESERVA_DETALLE_CLEAR:
            return {
                ...state,
                data: []
            }
        case ACTIVIDAD_RESERVA_DETALLE_LOADING:
            return {
                ...state,
                loading: payload
            }
        default:
            return state;
    }
}

export default ActividadReservaDetalles;
