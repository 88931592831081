import { DetalleLegoland, BookingDetail } from '../../../interfaces/detailBooking';
import { Comentario } from "../../../interfaces/comentario";
import {
    BOOKING_DETAIL_CLEAR, BOOKING_DETAIL_IS_EDIT_COMMENT, BOOKING_DETAIL_IS_NOTA_CREDITO,
    BOOKING_DETAIL_SET_COMENTARIOS, BOOKING_DETAIL_SET_OPEN_FORM,
    BOOKING_DETAIL_SET_PASAJEROS,
    BOOKING_DETAIL_SET_PICKUP_DROPOFFS, BOOKING_DETAIL_SET_PRODUCTOS_BOOKING, BOOKING_DETAIL_SET_PRODUCTOS_NOTA_CREDITO,
    BOOKING_DETAIL_SET_SELECT, BOOKING_DETAIL_SET_SELECT_COMMENT, BOOKING_DETAIL_SET_PRODUCT_COMPONENTS
} from './actionsTypes';
import { BookingDetailPasajero } from '../../../interfaces/bookingDetailPasajero';
import { PickUpDropOff } from "../../../interfaces/pickupDrop";

export const setSelectedBookingDetail = (item: BookingDetail) => ({
    type: BOOKING_DETAIL_SET_SELECT,
    payload: item
});

export const setComments = (comentarios: Comentario[]) => ({
    type: BOOKING_DETAIL_SET_COMENTARIOS,
    payload: comentarios
});

export const setPax = (paxs: BookingDetailPasajero[]) => ({
    type: BOOKING_DETAIL_SET_PASAJEROS,
    payload: paxs
});

export const setComponents = (components: DetalleLegoland[]) => ({
    type: BOOKING_DETAIL_SET_PRODUCT_COMPONENTS,
    payload: components.sort((a, b) => a.Orden - b.Orden)
});

export const setPickUpDropOffs = (pickupDropoffs: PickUpDropOff[]) => ({
    type: BOOKING_DETAIL_SET_PICKUP_DROPOFFS,
    payload: pickupDropoffs
});

export const clearBD = () => ({
    type: BOOKING_DETAIL_CLEAR
});

export const setOpenForm = (openForm: boolean) => ({
    type: BOOKING_DETAIL_SET_OPEN_FORM,
    payload: openForm
});

export const setSelectComment = (comment: Comentario) => ({
    type: BOOKING_DETAIL_SET_SELECT_COMMENT,
    payload: comment
});

export const setIsEditComment = (isEdit: boolean) => ({
    type: BOOKING_DETAIL_IS_EDIT_COMMENT,
    payload: isEdit
});

export const setIsNotaCredito = (isNotaCredito: boolean) => ({
    type: BOOKING_DETAIL_IS_NOTA_CREDITO,
    payload: isNotaCredito
});

export const setProductosBooking = (productosBooking: BookingDetail[]) => ({
    type: BOOKING_DETAIL_SET_PRODUCTOS_BOOKING,
    payload: productosBooking
});

export const setProductosNotaCredito = (productosNC: BookingDetail[]) => ({
    type: BOOKING_DETAIL_SET_PRODUCTOS_NOTA_CREDITO,
    payload: productosNC
});