import { apiSucesorValido } from '../constants/api';
import { get, post, postJSON, put } from './api_helper';
import moment from 'moment';
import { ReservaDetalle } from '../interfaces/reservasDetalle';
import { ActividadReserva, ActividadReservaDetalle, SucesorValido } from '../interfaces/respuestas';
import { Reserva } from '../interfaces/reserva';
import { EstadoReservaDetalle } from '../pages/Reservas/components/reservas/AccionesDetalle';
import { ReservaDetallesChangeSend, ReservaDetalleSend } from '../interfaces/sends';
import { esObjetoVacio, agruparPor, sinEstado } from './utils';
import { dateToYY_MM_DD } from './utilsDate';
import { groupBy } from '@progress/kendo-data-query';
import { Estado } from '../interfaces/commons';

/**
 * Actualiza el detalle de la reserva con el comnetario que ingresa el usuario
 * @param reservaD
 * @param comentario
 * @returns
 */
export const putReservaDetalle = (reservaD: ReservaDetalle, comentario: string) => {
    const sendData: ReservaDetalleSend = {
        Acomodacion: reservaD.Acomodacion,
        AcomodacionDescripcion: reservaD.AcomodacionDescripcion,
        ActividadId: reservaD.ActividadId,
        Cantidad: reservaD.Cantidad,
        CantidadAdulto: reservaD.CantidadAdulto,
        CantidadInfante: reservaD.CantidadInfante,
        CantidadNino: reservaD.CantidadNino,
        Comentario: comentario,
        EstadoId: reservaD.EstadoId,
        Fecha: reservaD.Fecha,
        FechaFin: moment(reservaD.FechaFin).format('YYYY-MM-DD'),
        FechaInicio: moment(reservaD.FechaInicio).format('YYYY-MM-DD'),
        ReservaDetalleId: reservaD.ReservaDetalleId,
        ReservaId: reservaD.ReservaId,
        TotalPasajeros: reservaD.TotalPasajeros,
        TotalTarifa: reservaD.TotalTarifa,
        VueloId: reservaD.VueloId,
        EsReservaVigente: reservaD.EsReservaVigente,
    }
    return put(`ReservaDetalles(${reservaD.ReservaDetalleId})`, sendData);
}
/**
 * Obtiene el detalle de una reserva y muestra dependiendo de los estados
 * @param reservaId
 * @param estadoReserva
 * @param estadosReservas
 * @returns
 */
export const getReservaDetalleBy = (reservaId: number, estadoReserva: number, estadosReservas: any, estadoPath: string) => {
    if (sinEstado(estadoReserva)) {
        throw new Error('No se encontró el estado de la reserva. Por favor, contacte a soporte.');
    }
    return get(`ReservaDetalles?$expand=Estado($select=Nombre),Actividad($expand=Comentario($expand=TipoComentario)) & $filter=ReservaId eq ${reservaId}`)
        .then((resp) => {
            const reservas = resp.value;
            let newReservas: ReservaDetalle[] = [];

            if (estadosReservas[estadoPath].EstadoId === estadosReservas['Negado'].EstadoId) {
                newReservas = reservas.filter((r: ReservaDetalle) => (r.EstadoId === estadosReservas['Negado'].EstadoId));
                return addComentario(newReservas);
            }

            if (estadosReservas[estadoPath].EstadoId === estadosReservas['Cancelado'].EstadoId) {
                newReservas = reservas.filter((r: ReservaDetalle) => (r.EstadoId === estadosReservas['Cancelado'].EstadoId));
                return addComentario(newReservas);
            }

            if (estadoReserva === estadosReservas['Reservado'].EstadoId) {
                newReservas = reservas.filter((r: ReservaDetalle) => (r.EsReservaVigente === true));
                return addComentario(newReservas);
            }

            newReservas = reservasProcesar(reservas, estadoReserva, estadosReservas);

            if (estadoReserva === estadosReservas['Modificación Pendiente'].EstadoId ||
                estadoReserva === estadosReservas['Cancelación Pendiente'].EstadoId) {
                newReservas = quitarReservaByEstado(newReservas, 'Cancelado', estadosReservas);
                newReservas = quitarReservaByEstado(newReservas, 'Negado', estadosReservas);
            }

            const newReservasComentarios = newReservas.map((rd: ReservaDetalle) => {
                rd.FechaInicio = moment(rd.FechaInicio).toDate();
                rd.FechaFin = moment(rd.FechaFin).toDate();
                const comentariosSerivicios = rd.Actividad.Comentario.map((cs: any) => {
                    if (cs.TipoComentario.Nombre === 'Servicio') {
                        return cs.Comentario1;
                    } else return [];
                });
                const comentariosOperactivos = rd.Actividad.Comentario.map((co: any) => {
                    if (co.TipoComentario.Nombre === 'Operación') {
                        return co.Comentario1;
                    } else return [];
                });
                rd.comentarioServicio = comentariosSerivicios.join('\n');
                rd.comentarioOperativo = comentariosOperactivos.join('\n');
                return rd;
            });
            return ordenarReservas(newReservasComentarios, estadosReservas);
        })
}


const addComentario = (reservas: ReservaDetalle[]) => {
    return reservas.map((rd: ReservaDetalle) => {
        rd.FechaInicio = moment(rd.FechaInicio).toDate();
        rd.FechaFin = moment(rd.FechaFin).toDate();
        const comentariosSerivicios = rd.Actividad.Comentario.map((cs: any) => {
            if (cs.TipoComentario.Nombre === 'Servicio') {
                return cs.Comentario1;
            } else return [];
        });
        const comentariosOperactivos = rd.Actividad.Comentario.map((co: any) => {
            if (co.TipoComentario.Nombre === 'Operación') {
                return co.Comentario1;
            } else return [];
        });
        rd.comentarioServicio = comentariosSerivicios.join('\n');
        rd.comentarioOperativo = comentariosOperactivos.join('\n');
        return rd;
    });
}

const quitarReservaByEstado = (reservas: ReservaDetalle[], estado: string, estados: any): ReservaDetalle[] => {
    const reservasCanceladas = reservas.filter((r: ReservaDetalle) => (r.EstadoId === estados[estado].EstadoId));
    const idCanceladas = reservasCanceladas.map((r: ReservaDetalle) => r.ReservaDetalleId);
    let reservasNoCanceladas: ReservaDetalle[] = [];
    if (idCanceladas.length > 0) {
        reservasNoCanceladas = reservas.filter((r: ReservaDetalle) => {
            return idCanceladas.indexOf(r.ReservaDetalleId) === -1;
        });
    } else {
        reservasNoCanceladas = reservas;
    }
    return reservasNoCanceladas;
}

const ordenarReservas = (reservas: ReservaDetalle[], estados: any): ReservaDetalle[] => {
    const soloReservados = reservas.filter((r: ReservaDetalle) => r.EstadoId === estados['Reservado'].EstadoId);
    const otrosEstados = reservas.filter((r: ReservaDetalle) => r.EstadoId !== estados['Reservado'].EstadoId);
    otrosEstados.sort((a, b) => a.FechaInicio > b.FechaInicio ? 1 : -1);
    soloReservados.sort((a, b) => a.FechaInicio > b.FechaInicio ? 1 : -1);
    return [...otrosEstados, ...soloReservados];
};

const reservasProcesar = (reservas: ReservaDetalle[], estadoReserva: number, estados: any): ReservaDetalle[] => {
    let reservaProcesada: ReservaDetalle[] = [];
    const reservasPorActividad = agruparPor(reservas, r => r.ActividadId);
    Object.values(reservasPorActividad).forEach((reserva) => {
        if (reserva.length > 1 && estadoReserva === estados['Modificación Pendiente'].EstadoId) {
            const reservaModificadaResult = obtenerReservaModificada(reserva, estadoReserva, estados);
            reservaProcesada.push(reservaModificadaResult);
        } else {
            const reservaVigente = reserva.find((r: ReservaDetalle) => r.EsReservaVigente === true);
            reservaProcesada.push(reservaVigente || reserva[0]);
        }
    })

    return reservaProcesada;
}

const obtenerReservaModificada = (
    reservas: ReservaDetalle[],
    estadoReserva: number,
    estados: { [key: string]: Estado }
): ReservaDetalle => {
    const estadoModificacion = estados['Modificación Pendiente'].EstadoId;
    const estadoSolicitada = estados['Modificación Solicitada'].EstadoId;
    const estadoReservado = estados['Reservado'].EstadoId;

    const reservaModificada = reservas.find((r: ReservaDetalle) =>
        r.EsReservaVigente === false &&
        (r.EstadoId === estadoModificacion || r.EstadoId === estadoSolicitada)
    );

    if (!reservaModificada) {
        throw new Error('No se encontró la reserva modificada. Por favor, contacte a soporte.');
    }

    const reservaVigente = reservas.find((r: ReservaDetalle) =>
        r.EsReservaVigente === true && r.EstadoId === estadoReservado
    );

    if (!reservaVigente) {
        throw new Error('No se encontró la reserva vigente. Por favor, contacte a soporte.');
    }

    return {
        ...reservaModificada,
        CantidadAdulto: reservaModificada.CantidadAdulto - reservaVigente.CantidadAdulto,
        CantidadNino: reservaModificada.CantidadNino - reservaVigente.CantidadNino,
        CantidadInfante: reservaModificada.CantidadInfante - reservaVigente.CantidadInfante,
        Cantidad: reservaModificada.Cantidad - reservaVigente.Cantidad,
    };
};

/**
 * Obtiene las actividaddes de una reserva por fechas y tipo de actividad
 * @param tipoActividad
 * @param inicio
 * @param fin
 * @returns
 */
export const getActividadReservaBusiness = async (tipoActividad: string, inicio?: Date, fin?: Date) => {
    const feF = fin !== undefined ? dateToYY_MM_DD(fin) : ' ';
    const feI = inicio !== undefined ? dateToYY_MM_DD(inicio) : ' ';
    return get(`ActividadReservasBusiness(${feI},${feF},${tipoActividad})`);
}
/**
 * Obtiene una actividad de una reserva por
 * @param actividadReservaCabeceraId
 * @param bookingId
 * @param proveedorId
 * @param actividadId
 * @param tipo
 * @returns
 */
export const getActividadReservaBy = async (actividadReservaCabeceraId: number,
    bookingId: number,
    proveedorId: number,
    actividadId: number,
    tipo: string) => {
    const arb = await get(`ActividadReservasDetalleBusiness(${actividadReservaCabeceraId},${bookingId},${proveedorId},${actividadId},${tipo})`);
    return arb.map((ar: ActividadReservaDetalle) => {
        ar.fechaInicio = moment(ar.fechaInicio).toDate();
        ar.fechaFin = moment(ar.fechaFin).toDate();
        return ar;
    });
}
/**
 * Obtiene todos los detalles de varias reservas
 * @param promises
 * @returns
 */
export const getDetallesAll = async (promises: any) => {
    return Promise.all(promises).then(
        resp => {
            let result: any = [];
            result = [].concat(...resp);
            return result;
        }
    );

}

export const postGenerarReservas = async (datos: ActividadReserva, ard: ActividadReservaDetalle[]) => {
    const { proveedorId, bookingId } = datos;
    let actividades: number[] = [];
    ard.forEach(bd => {
        actividades.push(bd.actividadId);
    });
    const datosEnvio = {
        ProveedorId: proveedorId,
        BookingId: bookingId,
        ActividadId: actividades
    }
    return post(`GenerarReservasBusiness`, datosEnvio);
}

const getDetalles = async (bookings: any, tipo: string) => {
    return Promise.all(bookings.map(async (b: any) => {
        let detalle: any;
        let actividades: string[] = [];
        detalle = await getActividadReservaBy(b.actividadReservaCabeceraId, b.bookingId, b.proveedorId, b.actividadId, tipo);
        detalle.forEach((d: any) => {
            actividades.push(d.actividadId)
        });

        return Object.assign(b, {
            ActividadId: actividades
        });
    }));

}
/**
 * Procesa en forma masiva la generación de las reservas
 * @param bookings
 * @param tipo
 * @returns
 */
export const multiProcessGenerarReservas = async (bookings: ActividadReserva[], tipo: string) => {
    const bookingsNoBlock = bookings.filter(b => b.bloqueoReserva === false);
    const result = await getDetalles(bookingsNoBlock, tipo);
    let promesasGenerarReservas: any[] = [];
    result.forEach((pba: any) => {
        const sendData = {
            ProveedorId: pba.proveedorId,
            BookingId: pba.bookingId,
            ActividadId: pba.ActividadId
        }
        promesasGenerarReservas.push(post('GenerarReservasBusiness', sendData));
    });

    return Promise.all(promesasGenerarReservas);
}
/**
 *  Obtiene todas las reservas dependiendo del tipo de actividad con estado RP o CP
 * @param tipoActividad Tipo Hotel | Alimento
 * @returns Promise<Reserva[]>
 */
export const getReservasTipoEstados = async (tipoActividad: string, estados: number[], filtroReservado: string, estadosReserva: any): Promise<Reserva[]> => {
    let filtro = "";
    if (estados.length === 0 || estados[0] === 0) {
        return [];
    }

    if (estados[0] === estadosReserva['Negado'].EstadoId || estados[0] === estadosReserva['Cancelado'].EstadoId) {
        filtro = `$filter=TipoActividad eq '${tipoActividad}' and (EstadoId in (${estados}) or ReservaDetalle/any(rd:rd/EstadoId eq ${estados[0]}))`;
    }
    else {
        filtro = `$filter=TipoActividad eq '${tipoActividad}' and EstadoId in (${estados}) ${filtroReservado}`;
    }

    const resp = await get(`Reservas?ReservaId,EstadoId,EstadoFuturoId,ProveedorId,BookingId,TipoReservaId,CartaEnviada,UrlCartaEnviada,TipoActividad,Eliminado,
    &$expand=Estado($Select=Nombre),Proveedor($Select=RazonSocial,NombreCompleto),
    Booking($Select=BookingId,ClienteId,EstadoId,EjecutivoComercialId,Bloqueado,BookingCode,TipoBookingId;
        $expand=Cliente($Select=Nombre),
        EjecutivoComercial($Select=Nombre,Apellido;
            $expand=AsignacionEjecutivoOperativo($Select=AsignacionEjecutivoOperativoId;$expand=AgenteOperativo($Select=Nombre,Apellido,Cargo)))),
        TipoReserva($Select=Nombre) && ${filtro}`);
    return resp.value.map((reserva: Reserva) => {
        let ejecutivoName: string = '';
        let agenteName: string = '';
        let clienteName: string = '';
        if (reserva.Booking.EjecutivoComercial !== undefined) {
            ejecutivoName = `${reserva.Booking.EjecutivoComercial.Nombre} ${reserva.Booking.EjecutivoComercial.Apellido}`;
            if (reserva.Booking.EjecutivoComercial.AsignacionEjecutivoOperativo[0] !== undefined) {
                agenteName = `${reserva.Booking.EjecutivoComercial.AsignacionEjecutivoOperativo[0].AgenteOperativo.Nombre} ${reserva.Booking.EjecutivoComercial.AsignacionEjecutivoOperativo[0].AgenteOperativo.Apellido}`;
            }
            clienteName = `${reserva.Booking.Cliente.Nombre}`;
        }
        let booking = Object.assign({}, reserva.Booking, {
            EjecutivoComercialNames: ejecutivoName,
            ClienteNames: clienteName,
            AgenteOperativoNames: agenteName
        });
        reserva.Booking = booking;
        reserva.Booking.FechaInicio = moment(reserva.Booking.FechaInicio).toDate();
        reserva.Booking.FechaFin = moment(reserva.Booking.FechaFin).toDate();
        return reserva;
    })
}
/**
 * Filtra el total de reservas por el estado o estados enviados
 * @param data
 * @param estados
 * @returns
 */
export const reservaByEstado = (data: Reserva[], estados: number[]): Reserva[] => {

    if (estados.length > 1) {
        return data.filter(r => r.EstadoId === estados[0] || r.EstadoId === estados[1]);
    } else {
        return data.filter(r => estados[0] === r.EstadoId);
    }
}
/**
 *  Cuenta las reservas por estado
 * @param reservas
 * @param estadosActivos
 * @param estadosActuales
 * @returns
 */
export const totalEstadosReserva = (reservas: Reserva[], estadosActivos: any, estadosActuales: number[]) => {
    const rp = reservaByEstado(reservas, [
        estadosActivos['Reserva Pendiente'].EstadoId,
        estadosActivos['Modificación Pendiente'].EstadoId
    ]).length;
    const cp = reservaByEstado(reservas, [estadosActivos['Cancelación Pendiente'].EstadoId]).length;
    if (estadosActuales.length === 0) {
        return { rp, cp };
    } else {
        if (estadosActuales.includes(estadosActivos['Reserva Pendiente'].EstadoId)) {
            return { rp }
        }
        if (estadosActuales.includes(estadosActivos['Cancelación Pendiente'].EstadoId)) {
            return { cp }
        }
    }

}
/**
 * Obtiene todos los sucesores validos
 * @returns
 */
export const getSucesoresValidos = async (): Promise<SucesorValido[]> => {
    const svResp = await get(apiSucesorValido);
    return svResp.value;
}
/**
 * Obtiene los estados dependiendo del origen
 * @param origen
 * @param sv
 * @returns
 */
export const getDestinoByOrigen = (origen: number, sv: SucesorValido[]) => {
    let destino: EstadoReservaDetalle[] = [];
    sv.forEach(sucesor => {
        if (sucesor.Origen === origen) {
            destino.push({ id: sucesor.Destino, name: sucesor.DestinoNavigation.Nombre });
        }
    });
    return destino;
}

/**
 * Envia lista de pasajeros al proveedor
 * @param bookingId
 * @param proveedorId
 * @returns
 */
export const postEnviarListaPasajeros = async (bookingId: number, proveedorId: number) => {
    return post(`EnviarListaPasajeros(${bookingId},${proveedorId})`, '');
}

/**
 * Envia al proceso de eliminar por su id
 * @param reservaId
 * @returns
 */
export const postEliminarReservas = async (reservaId: number) => {
    return post(`EliminarReservas(${reservaId})`, '');
}
/**
 * Proceso post para negar la reserva
 * @param reservaId
 * @returns
 */
export const postNegarReservas = async (reservaId: number) => {
    return post(`NegarReservas(${reservaId})`, '');
}
/**
 * Prepara la reserva para ser enviada a procesar este proceso trabaja para una o varias
 * @param sendDetalle
 * @param tipo
 * @param reservaId
 * @returns
 */
const prepareParamsReserva = (sendDetalle: ReservaDetallesChangeSend[], tipo: string, reservaId?: number): any => {
    let sendData: any[] = [];
    if (tipo === 'Hotel') {
        sendData.push({
            ReservaId: reservaId,
            ReservasDetalles: sendDetalle.map((sd: any) => {
                delete sd.reservaId;
                return sd;
            })
        });
    }
    if (tipo === 'Alimento') {
        sendData = groupBy(sendDetalle, [{ field: "reservaId" }])
            .map((sd: any) => ({
                ReservaId: sd.items[0].reservaId,
                ReservasDetalles: sd.items.map((i: any) => ({
                    "reservaDetalleId": i.reservaDetalleId,
                    "estadoActualizadoId": i.estadoActualizadoId
                }))
            }));
    }
    return sendData;
}
/**
 * Actualiza los estados de los detalles de la reserva a un estado futuro seleccionado
 * @param enviarDetalle
 * @param tipo
 * @param reservaId
 * @returns
 */
export const postActualizarEstadoFuturoReservas = (enviarDetalle: ReservaDetallesChangeSend[], tipo: string, reservaId: number): any => {
    const datosEnvio = prepareParamsReserva(enviarDetalle, tipo, reservaId);
    return postJSON(`ActualizarEstadoFuturoReservas(${tipo})`, datosEnvio);
}
/**
 * Post para generar la carta de reserva
 * @param sendDetalle
 * @param tipoProducto
 * @param enviarCorreo
 * @param reservaId
 * @returns
 */
export const postGenerarCartaReserva = (enviarDetalle: ReservaDetallesChangeSend[], tipoProducto: string, enviarCorreo: boolean, reservaId?: number,) => {
    const datosEnvio = prepareParamsReserva(enviarDetalle, tipoProducto, reservaId);
    return postJSON(`GenerarCartaReserva(${tipoProducto},${enviarCorreo})`, datosEnvio);
}
/**
 * Post para procesar una reserva
 * @param enviarDetalle
 * @param tipo
 * @param reservaId
 * @returns
 */
export const postProcesarReserva = (enviarDetalle: ReservaDetallesChangeSend[], tipo: string, reservaId: any) => {
    let enviarDatos: any;
    if (typeof reservaId === 'number') {
        enviarDatos = prepareParamsReserva(enviarDetalle, tipo, reservaId);
    }
    if (Array.isArray(reservaId)) {
        enviarDatos = [];
        reservaId.forEach(r => {
            enviarDatos.push(...prepareParamsReserva(enviarDetalle.filter(d => d.reservaId === r), tipo, r));
        });
    }
    return postJSON('ProcesarReservas', enviarDatos);
}

export const listoParaConfirmar = (reserva: Reserva, detalle: ReservaDetalle[], estados: any): boolean => {
    if (esObjetoVacio(reserva) || esObjetoVacio(detalle)) {
        return true
    }
    if (reserva.CartaEnviada === true) {
        let respuesta = true;
        detalle.forEach(d => {
            if (estadoParaConfirmar(d.EstadoId, estados)) {
                respuesta = false;
            }
        });
        return respuesta;
    }
    return true;
}

export const listoParaConfirmarTodos = (detalle: ReservaDetallesChangeSend[]): boolean => {
    if (esObjetoVacio(detalle)) {
        return false;
    }
    let vectorExisteCartaEnviada: boolean[] = [];
    detalle.forEach(d => {
        if (d.cartaEnviada) {
            vectorExisteCartaEnviada.push(true);
        } else {
            vectorExisteCartaEnviada.push(false);
        }
    });
    if (vectorExisteCartaEnviada.includes(true)) {
        return true;
    } else {
        return false;
    }
}

export const listoParaEnviarCarta = (change: ReservaDetallesChangeSend[], estados: any): boolean => {
    if (change.length === 0) {
        return false
    }
    let vectorEstado: boolean[] = [];
    change.forEach(c => {
        if (estadosFinales(c.estadoActualizadoId, estados) || estadosIntermedios(c.estadoActualizadoId, estados)) {
            vectorEstado.push(true);
        } else {
            vectorEstado.push(false)
        }
    });
    if (vectorEstado.includes(false)) {
        return false
    } else {
        return true
    }
}

export const listoParaEnviarMultiplesCartas = (change: ReservaDetallesChangeSend[], estados: any): boolean => {
    if (change.length === 0) {
        return false;
    }
    let vectoEstado: boolean[] = [];
    change.forEach(c => {
        if (estadosIntermedios(c.estadoActualizadoId, estados)) {
            vectoEstado.push(true);
        }
    });
    if (vectoEstado.includes(true)) {
        return true;
    } else {
        return false;
    }

}

const estadosIntermedios = (estado: number, estados: any): boolean => {
    switch (estado) {
        case estados['Lista de Espera'].EstadoId: // lista de espera
            return true;
        case estados['Reserva Solicitada'].EstadoId: // reserva solicitada
            return true;
        case estados['Cancelación Solicitada'].EstadoId: // Cancelación solicitada
            return true;
        case estados['Lista de Espera Acordada'].EstadoId: // lista de espera acordada
            return true;
        case estados['Modificación Solicitada'].EstadoId: // Modificación solicitada
            return true;
        default:
            return false;
    }
}


export const estadosFinales = (estado: number, estados: any): boolean => {
    switch (estado) {
        case estados['Cancelado'].EstadoId: // Cancelado
            return true;
        case estados['Negado'].EstadoId: // Negado
            return true;
        case estados['Reservado'].EstadoId: // Reservado
            return true;
        default:
            return false;
    }
}

export const estadoParaConfirmar = (estado: number, estados: any): boolean => {
    switch (estado) {
        case estados['Reserva Solicitada'].EstadoId: // reserva solicitada
            return true;
        case estados['Cancelación Solicitada'].EstadoId: // Cancelación solicitada
            return true;
        case estados['Reservado'].EstadoId: // Reservado
            return true;
        case estados['Modificación Solicitada'].EstadoId: // Modificación solicitada
            return true;
        default:
            return false;
    }
}

export const soloSolicitados = (detalle: ReservaDetallesChangeSend[], estados: any) => {
    let reservas: number[] = [];
    detalle.forEach((cambios: any) => {
        if (cambios.estadoActualizadoId === estados['Reserva']['Reserva Solicitada'].EstadoId ||
            cambios.estadoActualizadoId === estados['Reserva']['Modificación Solicitada'].EstadoId
        ) {
            reservas.push(cambios.reservaId);
        }
    });
    return reservas;
}

export const contarActividadesBloqueadas = (actividadReservas: any) => {
    let contarNoBloqueadas = 0;
    actividadReservas.forEach((ac: any) => {
        if (!ac.bloqueoReserva) {
            contarNoBloqueadas++;
        }
    });
    return contarNoBloqueadas;
}