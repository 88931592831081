export const CATALOGOS_GET_LISTASELECCION = "CAT_GET_LISTASELECCION";
export const CATALOGOS_GET_ESTADO = "CAT_GET_ESTADO";
export const CATALOGOS_GET_RESERVAS_WIZZARD = "CAT_GET_RESERVAS_WIZZARD";

export const CATALOGOS_SET_LISTASELECCION = "CAT_SET_LS_GROUP";
export const CATALOGOS_SET_ESTADO = "CAT_SET_ESTADO";
export const CATALOGOS_SET_ID_NOMBRE = "CAT_SET_ID_NOMBRE";
export const CATALOGOS_SET_ID_LISTA_SELECCION = "CAT_SET_ID_LS";
export const CATALOGOS_SET_RESERVAS_WIZZARD = "CAT_SET_RESERVAS_WIZZARD";
export const CATALOGOS_SET_LISTASELECCION_DATA = "CAT_SET_LS_DATA";
export const CATALOGOS_SET_IS_NEW = "CAT_SET_IS_NEW";
export const CATALOGOS_SET_IS_EDIT = "CAT_SET_IS_EDIT";
export const CATALOGOS_SET_IS_DELETE = "CAT_SET_IS_DELETE";
export const CATALOGOS_DESABLED_BUTTONS = "CAT_DESACTIVAR_BOTONES";

export const CATALOGOS_SET_LISTASELECCION_AGRUPACIONES = "CAT_SET_LS_AGRUPACIONES";
export const CATALOGOS_SET_DROP_DOWNS_DATA = "CAT_SET_DROP_DOWNS_DATA";
export const CATALOGOS_ADD_LIST_AGRUP = "CAT_ADD_LIST_AGRUP";
export const CATALOGOS_SET_LS_TREE = "CAT_SET_LS_TREE";

export const CAT_SET_CURRENT_LS = "CAT_SET_CURRENT_LS";
export const CAT_SET_AMBITO_SELECTED = "SET_AMBITO_SELECTED";
export const CAT_OPEN_ELIMINAR = "CAT_OPEN_ELIMINAR";
export const CAT_SET_PROCESS_ELIMINAR = "CAT_SET_PROCESS_ELIMINAR";
export const CAT_ELIMINAR_LS = "CAT_ELIMINAR_LS";

export const CATALOGOS_ADD_LISTA_SELECCION = "CAT_ADD_LS";
export const CATALOGOS_NEW_LISTA_SELECCION = "CAT_NEW_LS";
export const CATALOGOS_ADD_LISTA_SELECCION_DATA = "CAT_ADD_LS_DATA";

export const CAT_OPEN_EDITING = "CAT_OPEN_EDITING";
export const CAT_EDIT_LISTA_SELECCION = "CAT_EDIT_LS";
export const CAT_SET_PROCESS_EDIT = "CAT_SET_PROCESS_EDIT";