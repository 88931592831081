export const apiBookings = "Bookings?$expand=SalesProfit,TipoBooking,Cliente,Idioma,EjecutivoComercial($expand=AsignacionEjecutivoOperativo($expand=AgenteOperativo)),Estado&$count=true";
export const apiRutas = "Rutas?$expand=LugarLlegada($select=Nombre),LugarSalida($select=Nombre)";
export const apiListaSeleccionVuelo = "ListasSeleccion?$filter=Ambito eq 'Vuelo.DiaOperacion'&select=Nombre,ListaSeleccionId,Valor";
export const apiListaSeleccionTipo = "ListasSeleccion?$filter=Ambito eq 'Vuelo.Tipo'";
export const apiEmbarcaciones = "Embarcaciones";
export const apiGeografia = "Geografias?filter=Nivel eq '2'";
export const apiBookingReservas = "BookingDetails?$select=bookingdetailId,estadoId,fechainicio,fechafin,CantidadHabitacion,cantidadadulto,cantidadnino,cantidadinfante & $expand=Estado($select=Nombre) & $expand=booking($expand=EjecutivoComercial($select=Nombre,Apellido),Cliente($select=Nombre,Apellido)) & $expand=producto($expand=Subfamilia($expand=proveedor($select=razonsocial)) ) & $filter=EstadoId in  (19, 21, 24, 33)";
export const apiReservaDetalles = '/ReservaDetalles?$expand=Reserva($expand=Booking($expand=EjecutivoComercial($select=Nombre,Apellido),Cliente($select=Nombre,Apellido)),Proveedor($Select=Nombre),Estado($select=Nombre)),Actividad($expand=BookingDetail($expand=Producto($select=Descripcion,Acomodaciondescripcion)))';
export const apiSucesorValido = '/SucesorValidos?$Expand=OrigenNavigation, DestinoNavigation';
export const apiVuelo = '/Vuelos?$expand=LugarSalida,LugarLlegada,Tipo&count=true';
export const apiMatrizAsigancionSalida = '/AsignacionEmbarcacionesBusiness';
export const apiBloqueoBoletosVuelos = 'BloqueoBoletoVuelos?$Select=BloqueoBoletoVueloId,Numero&$expand=DiaOperacion,Embarcacion';
export const apiBloqueoBoletosVuelosHotel = 'BloqueoBoletoVuelos?$Select=BloqueoBoletoVueloId,Numero&$expand=DiaOperacion,Embarcacion&$filter=EmbarcacionId eq null';
export const apiBloqueoBoletosBarcos = 'BloqueoBoletos?$expand=VueloIda($Select=Numero),VueloRetorno($Select=Numero),Embarcacion($Select=Nombre),Estado($Select=Nombre), SalidaEmbarcacion($expand=TipoSalida($Select=Nombre))&$count=true';
export const apiBloqueoBoletosHoteles = 'BloqueoBoletosHotel?$expand=VueloIda($Select=Numero),VueloRetorno($Select=Numero),Estado($Select=Nombre),Proveedor($Select=NombreComercial),ProductoPropio($Select=NombreCompleto)&$count=true';
export const apiProveedorBloqueo = 'Proveedores?$Select=ProveedorId,RazonSocial,NombreCompleto&$filter=GeneraBloqueo eq true and CategoriaId eq null';
export const apiProductoPropioBloqueo = '/Proveedores?$Select=ProveedorId,NombreCompleto&$filter=GeneraBloqueo eq true and Categoria ne null';
export const apiTarifaProveedor = 'TarifasProveedor?$expand=Especialidad,TipoContrato,Categoria,Actividad,UnidadTarifa,RegionTarifa'
export const apiActividadMaestra = 'Bookings?$expand=ActividadMaestra'
// Proveedor.
export const apiProveedor = 'Proveedores?$expand=Naturaleza,TipoContrato,Prioridad,CategoriaFinanciera,TipoIdentificacion,LugarOperacion,CiudadPago,Especialidad,Categoria,InstitucionBancaria,TipoPago,ConvenioPago,TipoCuentaBancaria';
export const apiListaSeleccionProveedorClase = 'ListasSeleccion?$filter=Ambito eq \'Proveedor.Clase\'&$select=ListaSeleccionId,Nombre,Valor,Ambito';
export const apiListaSeleccionProveedorEspecialidad = 'ListasSeleccion?$filter=Ambito eq \'Proveedor.Especialidad\'&$select=ListaSeleccionId,Nombre,Valor,Ambito &$orderby=Nombre';
export const apiListaSeleccionProveedorTipoIdentificacion = 'ListasSeleccion?$filter=Ambito eq \'Proveedor.TipoIdentificacion\'&$select=ListaSeleccionId,Nombre,Valor,Ambito';
export const apiListaSeleccionInstitucionBancaria = 'ListasSeleccion?$filter=Ambito eq \'Proveedor.InstitucionBancaria\'&$select=ListaSeleccionId,Nombre,Valor,Ambito';
export const apiListaSeleccionTipoPago = 'ListasSeleccion?$filter=Ambito eq \'Proveedor.TipoPago\'&$select=ListaSeleccionId,Nombre,Valor,Ambito';
export const apiListaSeleccionTipiCuentaBancaria = 'ListasSeleccion?$filter=Ambito eq \'Proveedor.TipoCuentaBancaria\'&$select=ListaSeleccionId,Nombre,Valor,Ambito';
export const apiListaSeleccionCategoriaFinanciera = 'ListasSeleccion?$filter=Ambito eq \'Proveedor.CategoriaFinanciera\'&$select=ListaSeleccionId,Nombre,Valor,Ambito';
export const apiListaSeleccionPrioridad = 'ListasSeleccion?$filter=Ambito eq \'Proveedor.Prioridad\'&$select=ListaSeleccionId,Nombre,Valor,Ambito';
export const apiListaSeleccionHabilidad = 'ListasSeleccion?$filter=Ambito eq \'Proveedor.Habilidades\'&$select=ListaSeleccionId,Nombre,Valor,Ambito';
// Asignacion
export const apiAsignacionProveedorActividad = 'AsignacionesProveedorActividades';
export const apiValidacionRapidaHeader = 'AsignacionesProveedorActividades?expand=Actividad(expand=ActividadMaestra,Producto,Vuelo),Proveedor(expand=Especialidad)&$count=true';
// Reserva boletos
export const urlContadorRP = 'Reservas?$expand=Estado&$filter=TipoActividad eq \'Boleto\' and Estado/Nombre eq \'Reserva Pendiente\' or Estado/Nombre eq \'Reserva Solicitada\'';
export const urlContadorRD = 'Reservas?$expand=Estado&$filter=TipoActividad eq \'Boleto\' and Estado/Nombre eq \'Emision Pendiente\' or Estado/Nombre eq \'Emision Solicitada\'';
export const urlActividadesBoletos = 'ReservasBoletosBusiness( , ,Boleto,true)'
export const urlReservaPendiente = 'Reservas?$expand=Estado($Select=Nombre),Proveedor($Select=RazonSocial,Acronimo),ReservaDetalle($expand=Actividad(expand=ActividadMaestra)),TipoReserva($Select=Nombre) && $filter=TipoActividad eq \'Boleto\' and Estado/Nombre eq \'Reserva Pendiente\' or Estado/Nombre eq \'Reserva Solicitada\' ';
export const urlEmitido = 'Reservas?select=ReservaId,EstadoId,RutaId,ProveedorId,BookingId,TipoReservaId,FechaReserva,OrdenPedido,CartaEnviada,UrlCartaEnviada,TipoActividad,ProductoPropio,FechaInicioProducto,FechaFinProducto,Ruta,FechaCreacion,Eliminado&$expand=Estado($Select=Nombre),Proveedor($Select=RazonSocial,Acronimo),Booking($expand=Cliente($Select=Nombre),EjecutivoComercial($Select=Nombre,Apellido)),TipoReserva($Select=Nombre),ReservaDetalle($select=ReservaDetalleId,ReservaId,VueloId,ActividadId,AsignacionPasajeroId,Fecha,EstadoId,EstadoFuturoId,TotalPasajeros,FechaInicio,FechaFin,FechaEmision,RecordVuelo,Extencion,Residencia,VueloIda,VueloRegreso,TipoBoleto,NumeroBoleto,TarifaNetaLan,IvaLan,ImpuestoLan,TotalLan,OrLan,NumeroBoletoAtado,EsReservaVigente;expand=Estado($Select=Nombre,EstadoId),Actividad($Select=ActividadId,ActividadMaestraId,ProductoId,EstadoId,TipoId,TotalPasajeros,Nombre,FechaInicio,FechaFin,BoletoIda,BoletoRegreso,Ruta,BloqueoReserva,ReservaProcesada;$expand=ActividadMaestra($Select=BookingCode,ActividadMaestraId,BookingId,FechaInicio,FechaFin)))&$filter=ReservaDetalle/any(c: c/Estado/Nombre eq \'Emitido\')';
export const urlReservado = 'Reservas?$expand=ReservaDetalle(expand=Estado,Actividad($expand=ActividadMaestra)),Estado($Select=Nombre),Proveedor($Select=RazonSocial,Acronimo),Booking($expand=Cliente($Select=Nombre),EjecutivoComercial($Select=Nombre,Apellido)),TipoReserva($Select=Nombre) && $filter=TipoActividad eq \'Boleto\' and Estado/Nombre eq \'Emision Pendiente\' or Estado/Nombre eq \'Emision Solicitada\'&& orderby=FechaInicioProducto ';
export const urlRemitido = 'Reservas?$expand=Estado($Select=Nombre),Proveedor($Select=RazonSocial,Acronimo),Booking($expand=Cliente($Select=Nombre),EjecutivoComercial($Select=Nombre,Apellido)),TipoReserva($Select=Nombre),ReservaDetalle(expand=Estado,Actividad($expand=ActividadMaestra))&$filter=ReservaDetalle/any(c: c/Estado/Nombre eq \'Remitido\')';
export const urlReservaDetalle = 'ReservaDetalles?$expand=Estado($select=Nombre),AsignacionPasajero($expand=Pasajero($expand=TipoPasajero)),Reserva($select=Ruta),OrdenPago,Actividad($expand=ActividadMaestra($select=BookingCode))  && $filter=ReservaId eq ';
// Deckplan
export const urlIsabela = "NumeroTipoCabinas?$select=NumeroTipoCabinaId,Numero&$expand=TipoCabina($select=Sigla,CantidadPaxCabina,Jerarquia;$expand=Cabina($select=CabinaId,Nombre)&$expand=Deck($select=Sigla;$expand=Embarcacion($select=Sigla)))&$filter=TipoCabina/Deck/Embarcacion/Sigla eq 'IS2'";
export const urlPinta = "NumeroTipoCabinas?$select=NumeroTipoCabinaId,Numero&$expand=TipoCabina($select=Sigla,CantidadPaxCabina,Jerarquia;$expand=Cabina($select=CabinaId,Nombre)&$expand=Deck($select=Sigla;$expand=Embarcacion($select=Sigla)))&$filter=TipoCabina/Deck/Embarcacion/Sigla eq 'LPT'";
export const urlSantaCruz = "NumeroTipoCabinas?$select=NumeroTipoCabinaId,Numero&$expand=TipoCabina($select=Sigla,CantidadPaxCabina,Jerarquia;$expand=Cabina($select=CabinaId,Nombre)&$expand=Deck($select=Sigla;$expand=Embarcacion($select=Sigla)))&$filter=TipoCabina/Deck/Embarcacion/Sigla eq 'SC2'";
//Matriz Planificacion
export const urlMatrizPlanificacion = 'AsignacionesProveedorActividades?$select=CantidadPasajeros,TipoPlanificacionId,Tarifa,ActividadId,SalidaEmbarcacionId,AsignacionProveedorActividadId,FechaInicio,FechaFin,FechaInicioTimeline,FechaFinTimeline,ObservacionProveedor,CodigoAsignacion&$expand=Idioma($select=Nombre),SalidaEmbarcacion($select=SalidaEmbarcacionId;$expand=ItinerarioEmbarcacion($select=Nombre)),Proveedor($select=ProveedorId,NombreCompleto;$expand=Especialidad($select=Nombre)),Actividad($select=EsUnificada,AccionOperativaId,Nombre,TipoVuelo,TotalPasajeros,HoraVuelo,HoraInicio;$expand=Producto($select=CodigoOperativo),Vuelo($select=Numero),BookingDetail($select=BookingDetailId;$expand=Booking($select=BookingCode))),TipoPlanificacion($select=Nombre),OrdenTrabajo($select=OrdenTrabajoId;$expand=OrdenPago($select=OrdenPagoId))';

//Historico de reserva
export const urlHistoricoReserva = 'CartasReservaHistorico?$select=UsuarioUltimaModificacion,FechaUltimaModificacion,UrlCartaReserva,OrdenPedido,TipoCartaReserva,FechaCreacion&$expand=Reserva($select=TipoActividad;$expand=Booking($select=BookingCode),Proveedor($select=NombreCompleto))&$count=true';