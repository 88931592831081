// @ts-ignore
import NotificationCenter from "react-notification-center-component";
import { useCurrentAccount } from "../hooks/useCurrentAccount";
import { useEffect, useState } from "react";
import { LoadingSmall } from "../common/LoadingSmall";

export const Notificaciones = () => {
    const appIdNotification = process.env.REACT_APP_ID_NOTIFICATION!;
    const {userEmail} = useCurrentAccount();
    const [nuevaNotificacion, setNuevaNotificacion] = useState(false);

    useEffect(() => {
       // Espera hasta que el script de Ravenhub esté listo
    if (window.Raven && userEmail !== '') {
        const client = new window.Raven.Client({
          appId: appIdNotification,
          subscriberId: userEmail,
        });
  
        // Escucha los eventos de notificaciones
        client.on('notification', () => {
          // Cambia el estado cuando llega una nueva notificación
          setNuevaNotificacion(true);
        });
    }
    }, [userEmail]);

    const handleNotificationClick = () => {
        setNuevaNotificacion(false);
    }

  return (
    <div style={{ paddingTop: "1.3rem" }}>
    {
      userEmail != '' ? (
        <NotificationCenter
          className="dropdown d-none d-lg-inline-block ms-1"
          appId={appIdNotification}
          subscriberId={userEmail}
          onClick={handleNotificationClick}
          hasNewNotifications={nuevaNotificacion}
        />
      ): <LoadingSmall />
    }
  </div>
  )
}
