import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { CATALOGOS_SET_IS_DELETE, CATALOGOS_SET_IS_EDIT, CATALOGOS_SET_LISTASELECCION, CATALOGOS_SET_LISTASELECCION_DATA, CAT_EDIT_LISTA_SELECCION, CAT_ELIMINAR_LS, CAT_OPEN_EDITING, CAT_OPEN_ELIMINAR, CAT_SET_AMBITO_SELECTED, CAT_SET_CURRENT_LS, CAT_SET_PROCESS_EDIT } from './CatalogosTypes';
import { Action } from "../../interfaces/general";
import { notifications } from "../../helpers/notifications";
import { deleteListaSeleccion, putListaSeleccion } from "../../helpers/apiCatalogo";
import { ListaSeleccion } from "../../interfaces/listaSeleccion";
import { RootState } from "..";


function* activeButtons({ payload }: Action<any>) {

    yield put({ type: CATALOGOS_SET_IS_EDIT, payload: false });
    yield put({ type: CATALOGOS_SET_IS_DELETE, payload: false });
}


function* eliminarCatalogoLS({ payload }: Action<ListaSeleccion>) {
    const { listaSeleccionData, listaSeleccion } = yield select((state: RootState) => state.Catalogos);
    yield put({ type: CATALOGOS_SET_IS_DELETE, payload: true });
    try {
        yield call(deleteListaSeleccion, payload.ListaSeleccionId);
        const listaSeleccionExcluidaEliminada = listaSeleccionData.filter((ls: ListaSeleccion) => ls.ListaSeleccionId !== payload.ListaSeleccionId);
        yield put({ type: CATALOGOS_SET_LISTASELECCION_DATA, payload: listaSeleccionExcluidaEliminada });
        yield put({ type: CAT_SET_CURRENT_LS, payload: {} });
        let nuevalistaSeleccion;
        if (Object.values(listaSeleccion[payload.Ambito]).length === 1) {
            yield put({ type: CAT_SET_AMBITO_SELECTED, payload: '' });
            const { [payload.Ambito]: listaAfectada, ...nuevaLista } = listaSeleccion;
            nuevalistaSeleccion = { ...nuevaLista };
        } else {

            const { [payload.Ambito]: listaAfectada, ...nuevaLista } = listaSeleccion;
            const { [payload.Nombre]: omitirItem, ...otros } = listaAfectada;
            nuevalistaSeleccion = { ...nuevaLista, [payload.Ambito]: otros };
        }
        yield put({ type: CATALOGOS_SET_LISTASELECCION, payload: nuevalistaSeleccion });
        yield put({ type: CATALOGOS_SET_IS_EDIT, payload: true });
        yield put({ type: CATALOGOS_SET_IS_DELETE, payload: true });

        notifications({ title: 'Exito', message: `Catalogo Eliminado`, toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
        yield put({ type: CATALOGOS_SET_IS_EDIT, payload: false });
        yield put({ type: CATALOGOS_SET_IS_DELETE, payload: false });
    } finally {
        yield put({ type: CAT_OPEN_ELIMINAR, payload: false });
    }

}

function* editandoCatalogoLS({ payload }: Action<ListaSeleccion>) {
    const { listaSeleccionData } = yield select((state: RootState) => state.Catalogos);
    try {
        yield put({ type: CAT_OPEN_EDITING, payload: false });
        yield call(putListaSeleccion, payload);
        const listaSeleccionUpdate = listaSeleccionData.map((ls: ListaSeleccion) => {
            if (ls.ListaSeleccionId === payload.ListaSeleccionId) {
                return { ...payload, selected: true };
            }
            return ls;
        }
        );
        yield put({ type: CATALOGOS_SET_LISTASELECCION_DATA, payload: listaSeleccionUpdate });
        notifications({ title: 'Exito', message: `Catalogo Actualizado`, toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: CAT_SET_PROCESS_EDIT, payload: false });
    }
}

function* watchEditarCatalogoLS() {
    yield takeLatest(CAT_EDIT_LISTA_SELECCION, editandoCatalogoLS);

}

function* watchEliminarLS() {
    yield takeLatest(CAT_ELIMINAR_LS, eliminarCatalogoLS);
}

function* watchCurrentCatalogoListaSeleccion() {
    yield takeLatest(
        CAT_SET_CURRENT_LS,
        activeButtons
    );
}


export default function* CatalogoListaSeleccionSaga() {
    yield all([
        fork(watchCurrentCatalogoListaSeleccion),
        fork(watchEliminarLS),
        fork(watchEditarCatalogoLS)
    ]);
}

