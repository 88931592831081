import { Link, NavLink } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap"
import CardMaintenance from '../../components/Common/CardMaintenance';

export const Mantenimiento = () => {
  return (
      <section className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col xs="12" className="text-center">
              <div className="home-wrapper">
                <div className="mb-5">
                  <Link to="/login" className="d-block auth-logo" >
                    {/* <img src={logo} alt="logo" height="20" /> */}
                  </Link>
                </div>

                <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="maintenance-img">
                      {/* <img
                        src={maintenance}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      /> */}
                    </div>
                  </Col>
                </Row>
                <h3 className="mt-5">Upss algo salió mal..</h3>
                <p>Por favor espere un momento y vuela a intentar o retorne al inicio haciendo <NavLink to={'/'}>click aqui</NavLink>.</p>

                <Row>
                  <CardMaintenance>
                    <i className="bx bx-broadcast mb-4 h1 text-primary" />
                    <h5 className="font-size-15 text-uppercase">
                      Por que está en mantenimiento?
                    </h5>
                    <p className="text-muted mb-0">
                      Un sistema necesita mantenimiento para nejorar sus características y poder ser ágil y eficiente en su desempeño.
                    </p>
                  </CardMaintenance>

                  <CardMaintenance>
                    <i className="bx bx-time-five mb-4 h1 text-primary" />
                    <h5 className="font-size-15 text-uppercase">
                      Que es el fuera de servicio?
                    </h5>
                    <p className="text-muted mb-0">
                      Es el tiempo que toma en realizar un cambio dentro del sistema y que permite actualizarlo para un mejor funcionamiento.
                    </p>
                  </CardMaintenance>

                  <CardMaintenance>
                    <i className="bx bx-envelope mb-4 h1 text-primary" />
                    <h5 className="font-size-15 text-uppercase">
                      Necesita soporte?
                    </h5>
                    <p className="text-muted mb-0">
                      En caso de necesitar ayuda inmediatamente, póngase en contacto con el equipo de soporte...
                      <Link
                        to="mailto:no-reply@domain.com"
                        className="text-decoration-underline"
                      >
                        soporte@metropolitan-touring.com
                      </Link>
                    </p>
                  </CardMaintenance>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
  )
}
