import { Action, PathEstado } from '../../../interfaces/general';
import { Proveedor } from '../../../interfaces/proveedor';
import {
    RESERVAS_ACTIONS_OPEN_CONFIRM,
    RESERVAS_ACTIONS_OPEN_DENY,
    RESERVAS_ACTIONS_OPEN_GENERATED,
    RESERVAS_ACTIONS_OPEN_LETTER,
    RESERVAS_ACTIONS_OPEN_UNLOCK,
    RESERVAS_ACTIONS_SET_CURRENT_PATH,
    RESERVAS_ACTIONS_SET_TIPO,
    RESERVAS_ACTIONS_OPEN_COMMENT,
    RESERVAS_ACTIONS_OPEN_MULTI_BLOCK,
    RESERVAS_ACTIONS_IS_ALL_ACTIVE,
    RESERVAS_ACTIONS_OPEN_MULTI_LETTER,
    RESERVAS_ACTIONS_OPEN_CONFIRM_ALL,
    RESERVAS_ACTIONS_PROCESSING_LETTER,
    RESERVAS_ACTIONS_SET_CURRENT_PROVEEDOR,
    RESERVAS_ACTIONS_SET_ALL_PROVEEDORES,
    RESERVAS_ACTIONS_SET_CURRENT_BOOKINGS,
    RESERVAS_ACTIONS_SET_IS_PROCESSING_MULTI_BLOCK,
} from './type';


interface StateReservasActions {
    currentPathEstado: PathEstado;
    currentProveedor: Proveedor;
    currentBookings: any;
    allProveedor: string[];
    isOpenDeny: boolean;
    isOpenGenerated: boolean;
    isOpenGeneratedMulti: boolean;
    isOpenLetter: boolean;
    isProcessingMultiBlock: boolean;
    isProcessingLetter: boolean;
    isOpenMultiLetter: boolean;
    isOpenUnlock: boolean;
    isOpenConfirm: boolean;
    isOpenConfirmAll: boolean;
    isOpenComentario: boolean;
    tipo: 'Hotel' | 'Alimento' | '';
    isAll: boolean;
    reservaParams: any;
    detalleParams: any[];
}

const INITIAL_STATE: StateReservasActions = {
    currentPathEstado: { name: 'Actividades', estadosId: [], siglas: 'A', to: 'actividades', color: "RGB( 135, 206, 250)", colorInactivo: "RGB( 135, 206, 250, 0.5)" },
    currentProveedor: {} as Proveedor,
    currentBookings: {} as any,
    allProveedor: [],
    isOpenDeny: false,
    isOpenGenerated: false,
    isOpenGeneratedMulti: false,
    isOpenLetter: false,
    isProcessingMultiBlock: false,
    isProcessingLetter: false,
    isOpenMultiLetter: false,
    isOpenUnlock: false,
    isOpenConfirm: false,
    isOpenConfirmAll: false,
    isOpenComentario: false,
    tipo: '',
    isAll: false,
    reservaParams: {},
    detalleParams: []
}
const actions: Action<boolean | PathEstado | any> = {
    type: '',
    payload: false
}
export const reservasActions = (state = INITIAL_STATE, { type, payload } = actions): StateReservasActions => {
    switch (type) {
        case RESERVAS_ACTIONS_OPEN_GENERATED:
            return {
                ...state,
                isOpenGenerated: payload
            };
        case RESERVAS_ACTIONS_OPEN_UNLOCK:
            return {
                ...state,
                isOpenUnlock: payload
            }
        case RESERVAS_ACTIONS_SET_CURRENT_PATH:
            return {
                ...state,
                currentPathEstado: payload
            }
        case RESERVAS_ACTIONS_OPEN_DENY:
            return {
                ...state,
                isOpenDeny: payload
            }
        case RESERVAS_ACTIONS_OPEN_LETTER:
            return {
                ...state,
                isOpenLetter: payload
            }
        case RESERVAS_ACTIONS_PROCESSING_LETTER:
            return {
                ...state,
                isProcessingLetter: payload
            }
        case RESERVAS_ACTIONS_OPEN_CONFIRM:
            return {
                ...state,
                isOpenConfirm: payload
            }
        case RESERVAS_ACTIONS_SET_TIPO:
            return {
                ...state,
                tipo: payload
            }
        case RESERVAS_ACTIONS_OPEN_COMMENT:
            return {
                ...state,
                isOpenComentario: payload
            }
        case RESERVAS_ACTIONS_OPEN_MULTI_BLOCK:
            return {
                ...state,
                isOpenGeneratedMulti: payload
            }
        case RESERVAS_ACTIONS_IS_ALL_ACTIVE:
            return {
                ...state,
                isAll: payload
            }
        case RESERVAS_ACTIONS_OPEN_MULTI_LETTER:
            return {
                ...state,
                isOpenMultiLetter: payload
            }
        case RESERVAS_ACTIONS_OPEN_CONFIRM_ALL:
            return {
                ...state,
                isOpenConfirmAll: payload
            }
        case RESERVAS_ACTIONS_SET_CURRENT_PROVEEDOR:
            return {
                ...state,
                currentProveedor: payload
            }
        case RESERVAS_ACTIONS_SET_ALL_PROVEEDORES:
            return {
                ...state,
                allProveedor: payload
            }
        case RESERVAS_ACTIONS_SET_CURRENT_BOOKINGS:
            return {
                ...state,
                currentBookings: payload
            }
        case RESERVAS_ACTIONS_SET_IS_PROCESSING_MULTI_BLOCK:
            return {
                ...state,
                isProcessingMultiBlock: payload
            }
        default:
            return state;
    }
}


export default reservasActions;