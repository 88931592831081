export const RESERVAS_ACTIONS_OPEN_UNLOCK = 'RESERVAS_ACTIONS_OPEN_UNLOCK';


export const RESERVAS_ACTIONS_OPEN_GENERATED = 'RESERVAS_ACTIONS_OPEN_GENERATED';

export const RESERVAS_ACTIONS_OPEN_DENY = "RESERVAS_ACTIONS_OPEN_DENY";

export const RESERVAS_ACTIONS_OPEN_LETTER = "RESERVAS_ACTIONS_OPEN_LETTER";
export const RESERVAS_ACTIONS_PROCESSING_LETTER = "RESERVAS_ACTIONS_PROCESSING_LETTER";

export const RESERVAS_ACTIONS_OPEN_MULTI_LETTER = "RESERVAS_ACTIONS_OPEN_MULTI_LETTER";

export const RESERVAS_ACTIONS_OPEN_CONFIRM = "RESERVAS_ACTIONS_OPEN_CONFIRM";
export const RESERVAS_ACTIONS_OPEN_CONFIRM_ALL = 'RESERVAS_ACTIONS_OPEN_CONFIRM_ALL';

export const RESERVAS_ACTIONS_OPEN_COMMENT = "RESERVAS_ACTIONS_OPEN_COMMENT";


export const RESERVAS_ACTIONS_SET_CURRENT_PATH = 'RESERVAS_ACTIONS_SET_CURRENT_PATH';
export const RESERVAS_ACTIONS_PROCESS_CURRENT_PATH = 'RESERVAS_ACTIONS_PROCESS_CURRENT_PATH';

export const RESERVAS_ACTIONS_SET_TIPO = 'RESERVAS_ACTIONS_SET_TIPO';

export const RESERVAS_ACTIONS_OPEN_MULTI_BLOCK = 'RESERVAS_ACTIONS_OPEN_MULTI_BLOCK';

export const RESERVAS_ACTIONS_IS_ALL_ACTIVE = 'RESERVAS_ACTIONS_IS_ALL_ACTIVE'; 


export const RESERVAS_ACTIONS_PROCESS_ESTADOS_FUTUROS = 'RESERVAS_ACTIONS_PROCESS_ESTADOS_FUTUROS';

export const RESERVAS_ACTIONS_SET_CURRENT_PROVEEDOR = 'RESERVAS_ACTIONS_SET_CURRENT_PROVEEDOR';

export const RESERVAS_ACTIONS_SET_ALL_PROVEEDORES = 'RESERVAS_ACTIONS_SET_ALL_PROVEEDORES';

export const RESERVAS_ACTIONS_SET_CURRENT_BOOKINGS = 'RESERVAS_ACTIONS_SET_CURRENT_BOOKINGS';

export const RESERVAS_ACTIONS_SET_IS_PROCESSING_MULTI_BLOCK = 'RESERVAS_ACTIONS_SET_IS_PROCESSING_MULTI_BLOCK';