import { ReservaDetalle } from "../../../interfaces/reservasDetalle";
import { RESERVAS_DETALLES_CLEAR, RESERVAS_DETALLES_SET } from './types';

export const setReservasDetalles = (reservasDetalles: ReservaDetalle[]) => ({
    type: RESERVAS_DETALLES_SET,
    payload: reservasDetalles
});

export const reservasDetallesClear = () => ({
    type: RESERVAS_DETALLES_CLEAR
})