import { ListaSeleccion } from "../interfaces/listaSeleccion";
import { del, post, put } from "./api_helper";


export const deleteListaSeleccion = async (id: number): Promise<any> => {
    const response = await del(`/ListasSeleccion(${id})`);
    return response;
}

export const postListaSeleccion = async (listaSeleccionNueva: any): Promise<any> => {
    const response = await post("/ListasSeleccion", listaSeleccionNueva);
    return response;
}

export const putListaSeleccion = async (listaSeleccionEditada: ListaSeleccion): Promise<any> => {
    const response = await put(`/ListasSeleccion(${listaSeleccionEditada.ListaSeleccionId})`, listaSeleccionEditada);
    return response;
}