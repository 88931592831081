import { State } from '@progress/kendo-data-query';
import { TAKE } from '../../../constants/default';
import { Booking, BookingsState, IOpenModalConfirmar, ItemBooking } from "../../../interfaces/booking";
import { Action } from "../../../interfaces/general";
import {
    BOOKING_SET_CURRENT_BOOKING,
    BOOKING_SET_DATA,
    BOOKING_SET_DATASTATE, BOOKING_SET_LOADING, BOOKING_SET_MODAL_CONFIRMACION,
    BOOKING_SET_MODAL_FACTURACION, BOOKING_SET_MODAL_NOTA_CREDITO, SET_BOOKINGS_RESERVAS, SET_SELECT_BOOKING_RESERVAS
} from "./actionsTypes";

export const initialBookingState: State = {
    take: TAKE,
    skip: 0,
    sort: [{ field: 'FechaCreacion', dir: 'desc' }]
}

const INIT_STATE: BookingsState = {
    loading: true,
    dataState: initialBookingState,
    bookings: { data: [] as Booking[], total: 0 },
    bookingsReservas: [] as ItemBooking[],
    selectBookingReserva: {} as ItemBooking,
    modalFacturacion: false,
    modalNotaCredito: false,
    modalConfirmacion: {} as IOpenModalConfirmar,
}

const action: Action<any> = {
    type: '',
    payload: ''
}

export const BookingReduce = (state = INIT_STATE, { type, payload } = action): BookingsState => {
    switch (type) {
        case BOOKING_SET_DATASTATE:
            return {
                ...state,
                dataState: payload
            }
        case BOOKING_SET_CURRENT_BOOKING:
            return {
                ...state,
                selectedBooking: payload
            }
        case BOOKING_SET_DATA:
            return {
                ...state,
                bookings: payload
            }
        case BOOKING_SET_MODAL_FACTURACION:
            return {
                ...state,
                modalFacturacion: payload
            }
        case BOOKING_SET_MODAL_NOTA_CREDITO:
            return {
                ...state,
                modalNotaCredito: payload
            }
        case BOOKING_SET_LOADING:
            return {
                ...state,
                loading: payload
            }
        case BOOKING_SET_MODAL_CONFIRMACION:
            return {
                ...state,
                modalConfirmacion: payload
            }
        case SET_BOOKINGS_RESERVAS:
            return {
                ...state,
                bookingsReservas: payload,
            }
        case SET_SELECT_BOOKING_RESERVAS:
            return {
                ...state,
                selectBookingReserva: payload
            }
        default:
            return {
                ...state
            }
    }
}

export default BookingReduce;