import { ActividadReserva } from '../../../interfaces/respuestas';
import {
    ACTIVIDAD_RESERVA_LOADING,
    ACTIVIDAD_RESERVA_SET_DATA,
    ACTIVIDAD_RESERVA_SET_SELECTED,
    ACTIVIDAD_RESERVA_SET_START,
} from './types';

export const setActividadReservasData = (actividadReserva: ActividadReserva) => ({
    type: ACTIVIDAD_RESERVA_SET_DATA,
    payload: actividadReserva
});

export const setSelectedAR = (ar: ActividadReserva) => ({
    type: ACTIVIDAD_RESERVA_SET_SELECTED,
    payload: ar
});

export const setGeneratedStart = (start: boolean) => ({
    type: ACTIVIDAD_RESERVA_SET_START,
    payload: start
});

export const loadingAR = (loading: boolean) => ({
    type: ACTIVIDAD_RESERVA_LOADING,
    payload: loading
});