import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROLE } from "../interfaces/roles";
import { hasRole } from "../helpers/utils";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

interface PrivateRouteProps {
  children: JSX.Element;
  roles: ROLE[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  roles = [],
}) => {
  const location = useLocation();
  const accounts = useMsal().accounts;

  const userRoles = accounts[0]?.idTokenClaims?.roles ?? [];
  const isAutenticated = useIsAuthenticated();

  const mostrarCount = () => {
    if (process.env.REACT_APP_PRODUCTION === "false") {
      console.log("accounts", accounts);
    }
  };

  try {
    if (isAutenticated) {
      if (hasRole(userRoles, roles)) {
        return children;
      } else {
        mostrarCount();
        return <Navigate to="/prohibido" state={{ from: location }} replace />;
      }
    } else {
      mostrarCount();
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  } catch (e) {
    return <Navigate to="/maintenance" state={{ from: location }} replace />;
  }
};

export default PrivateRoute;
