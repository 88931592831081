import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '669ac662-3f93-47f2-82fc-9819cb578ea1',
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    }
});
appInsights.loadAppInsights();
export {reactPlugin, appInsights};
