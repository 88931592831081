import {
    DECKPLAN_ABRIR_CABINA,
    DECKPLAN_DESBLOQUEAR_CABINA,
    DECKPLAN_GET_PASAJEROS_LISTA,
    DECKPLAN_LOAD_DATA,
    DECKPLAN_OPEN_FORM_LOCK,
    DECKPLAN_SEND_YIELD_NOTIFICATION,
    DECKPLAN_SET_BLOQUEOS,
    DECKPLAN_SET_CABINAS,
    DECKPLAN_SET_DATA_ACOMODACIONES,
    DECKPLAN_SET_DATA_ASIGNAR,
    DECKPLAN_SET_DATA_MODAL_CABINA,
    DECKPLAN_SET_DATA_SALIDA, DECKPLAN_SET_DISPONIBILIDAD, DECKPLAN_SET_LOADING,
    DECKPLAN_SET_MODAL_CABINAS_RESTANTES,
    DECKPLAN_SET_MODAL_DESASIGNAR_TODO,
    DECKPLAN_SET_MODAL_YIELD,
    DECKPLAN_SET_NUMERO_TIPO_CABINA,
    DECKPLAN_SET_PASAJEROS_ASIGNADOS,
    DECKPLAN_SET_PASAJEROS_LISTA,
    DECKPLAN_SET_VISIBLE
} from "./actionsTypes";
import {
    Acomodacion,
    Assigned,
    CabinasInfo, DataAsignar,
    ICabinaBloqueada, IDispoDeckplan,
    ISalidaEmbarcacion,
    LoadDeckplanData,
    ModalData
} from "../../interfaces/deckplan";

export const setDeckplanLoadData = (item: LoadDeckplanData) => ({
    type: DECKPLAN_LOAD_DATA,
    payload: item
});

export const setPasajerosLista = (item: any) => ({
    type: DECKPLAN_SET_PASAJEROS_LISTA,
    payload: item
});

export const getPasajerosLista = () => ({
    type: DECKPLAN_GET_PASAJEROS_LISTA,
});

export const setCabinas = (item: CabinasInfo[]) => ({
    type: DECKPLAN_SET_CABINAS,
    payload: item
});

export const setVisible = (item: boolean) => ({
    type: DECKPLAN_SET_VISIBLE,
    payload: item
});

export const setOpenFormLock = (item: boolean) => ({
    type: DECKPLAN_OPEN_FORM_LOCK,
    payload: item
});

export const setNumeroTipoCabina = (item: number) => ({
    type: DECKPLAN_SET_NUMERO_TIPO_CABINA,
    payload: item
});

export const setDataSalida = (item: ISalidaEmbarcacion) => ({
    type: DECKPLAN_SET_DATA_SALIDA,
    payload: item
});

export const setPasajerosAsignados = (item: Assigned[]) => ({
    type: DECKPLAN_SET_PASAJEROS_ASIGNADOS,
    payload: item
});

export const setModalYield = (item: boolean) => ({
    type: DECKPLAN_SET_MODAL_YIELD,
    payload: item
});

export const sendYieldNotification = () => ({
    type: DECKPLAN_SEND_YIELD_NOTIFICATION,
});

export const desbloquearCabina = (item: number) => ({
    type: DECKPLAN_DESBLOQUEAR_CABINA,
    payload: item
});

export const setBloqueos = (item: ICabinaBloqueada[]) => ({
    type: DECKPLAN_SET_BLOQUEOS,
    payload: item
});

export const setModalCabina = (item: ModalData) => ({
    type: DECKPLAN_SET_DATA_MODAL_CABINA,
    payload: item
});

export const abrirCabina = (cabina: number) => ({
    type: DECKPLAN_ABRIR_CABINA,
    payload: cabina
});

export const setDataAsignar = (data: DataAsignar) => ({
    type: DECKPLAN_SET_DATA_ASIGNAR,
    payload: data
});

export const setDataAcomodaciones = (data: Acomodacion[]) => ({
    type: DECKPLAN_SET_DATA_ACOMODACIONES,
    payload: data
});

export const setModalDessignarTodo = (item: boolean) => ({
    type: DECKPLAN_SET_MODAL_DESASIGNAR_TODO,
    payload: item
});

export const setModalCabinasRestantes = (item: boolean) => ({
    type: DECKPLAN_SET_MODAL_CABINAS_RESTANTES,
    payload: item
});

export const setLoading = (item: boolean) => ({
    type: DECKPLAN_SET_LOADING,
    payload: item
});

export const setDisponibilidad = (item: IDispoDeckplan[]) => ({
    type: DECKPLAN_SET_DISPONIBILIDAD,
    payload: item
});