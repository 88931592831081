import { Action, PathEstado } from '../../interfaces/general';
import { ListaSeleccion } from '../../interfaces/listaSeleccion';
import {
    CATALOGOS_ADD_LISTA_SELECCION,
    CATALOGOS_ADD_LIST_AGRUP,
    CATALOGOS_DESABLED_BUTTONS,
    CATALOGOS_NEW_LISTA_SELECCION,
    CATALOGOS_SET_DROP_DOWNS_DATA,
    CATALOGOS_SET_ESTADO,
    CATALOGOS_SET_ID_LISTA_SELECCION,
    CATALOGOS_SET_ID_NOMBRE,
    CATALOGOS_SET_IS_DELETE,
    CATALOGOS_SET_IS_EDIT,
    CATALOGOS_SET_IS_NEW,
    CATALOGOS_SET_LISTASELECCION, CATALOGOS_SET_LISTASELECCION_AGRUPACIONES,
    CATALOGOS_SET_LISTASELECCION_DATA,
    CATALOGOS_SET_LS_TREE,
    CATALOGOS_SET_RESERVAS_WIZZARD,
    CATALOGOS_ADD_LISTA_SELECCION_DATA
} from './CatalogosTypes';


export interface Seleccion {
    ListaSeleccionId: number;
    Nombre: string;
    Valor: string;
    Ambito: string;
    Descripcion?: any;
    Eliminado: boolean;
}

interface KeySeleccion {
    [key: string]: Seleccion;
}

interface ListaSeleccionGroup {
    [key: string]: KeySeleccion;
}

interface Estado {
    EstadoId: number;
    Nombre: string;
    Ambito: string;
    Eliminado: boolean;
}

interface KeyEstado {
    [key: string]: Estado;
}

interface ListaEstado {
    [key: string]: KeyEstado;
}

interface EstadoNombre {
    [key: string]: {
        nombre: string;
        siglas: string;
        color: string;
        colorInactivo: string;
    };
}


export interface IDListaSeleccion {
    ListaSeleccionId: number;
    Nombre: string;
    Valor: string;
    Ambito: string;
    Descripcion: null | string;
    FechaCreacion: string;
    FechaUltimaModificacion: string;
    UsuarioIdUltimaModificacion: number;
    UsuarioUltimaModificacion: string;
    UsuarioIdCreacion: number;
    UsuarioCreacion: string;
    Eliminado: boolean;
}

export interface ListaSeleccionTree {
    text: string;
    id: number;
    items: ListaSeleccionTree[];
}

export interface StateCatalogos {
    listaSeleccion: ListaSeleccionGroup;
    listaSeleccionData: ListaSeleccion[];
    estados: ListaEstado;
    idEstadoNombre: EstadoNombre;
    idListaSeleccion: IDListaSeleccion;
    reservasWizzard: Map<string, PathEstado>;
    isNew: boolean;
    isEditing: boolean;
    isDelete: boolean;
    listaSeleccionAgrupaciones: Record<string, any>;
    listaSeleccionTree: ListaSeleccionTree;
    dropDowndData: any[][];
}


const INITIAL_STATE: StateCatalogos = {
    listaSeleccion: {},
    listaSeleccionData: [],
    estados: {},
    idEstadoNombre: {},
    idListaSeleccion: {} as IDListaSeleccion,
    reservasWizzard: new Map<string, PathEstado>(),
    isNew: false,
    isEditing: true,
    isDelete: true,
    listaSeleccionAgrupaciones: {},
    dropDowndData: [],
    listaSeleccionTree: {
        text: '',
        id: 0,
        items: []
    }
}

const actions: Action<any> = {
    type: '',
    payload: ''
}

export const CatalogosReducer = (state = INITIAL_STATE, { type, payload } = actions): StateCatalogos => {
    switch (type) {
        case CATALOGOS_SET_LISTASELECCION:
            return {
                ...state,
                listaSeleccion: payload
            }
        case CATALOGOS_SET_LISTASELECCION_DATA:
            return {
                ...state,
                listaSeleccionData: payload
            }
        case CATALOGOS_SET_ESTADO:
            return {
                ...state,
                estados: payload
            }
        case CATALOGOS_SET_ID_NOMBRE:
            return {
                ...state,
                idEstadoNombre: payload
            }
        case CATALOGOS_SET_ID_LISTA_SELECCION:
            return {
                ...state,
                idListaSeleccion: payload
            }
        case CATALOGOS_SET_LS_TREE:
            return {
                ...state,
                listaSeleccionTree: payload
            }
        case CATALOGOS_SET_RESERVAS_WIZZARD:
            return {
                ...state,
                reservasWizzard: payload
            }
        case CATALOGOS_SET_IS_NEW:
            return {
                ...state,
                isNew: payload
            }
        case CATALOGOS_SET_IS_EDIT:
            return {
                ...state,
                isEditing: payload
            }
        case CATALOGOS_SET_IS_DELETE:
            return {
                ...state,
                isDelete: payload
            }
        case CATALOGOS_SET_LISTASELECCION_AGRUPACIONES:
            return {
                ...state,
                listaSeleccionAgrupaciones: payload
            }
        case CATALOGOS_ADD_LIST_AGRUP:
            console.log(payload, 'payload')
            return {
                ...state,
                listaSeleccionAgrupaciones: {
                    ...state.listaSeleccionAgrupaciones,
                    ...payload,
                }
            }
        case CATALOGOS_SET_DROP_DOWNS_DATA:
            return {
                ...state,
                dropDowndData: payload
            }
        case CATALOGOS_DESABLED_BUTTONS: {
            return {
                ...state,
                isEditing: payload,
                isDelete: payload
            }
        }
        case CATALOGOS_ADD_LISTA_SELECCION: {
            return {
                ...state,
                listaSeleccion: { ...state.listaSeleccion, ...payload }
            }
        }
        case CATALOGOS_ADD_LISTA_SELECCION_DATA: {
            return {
                ...state,
                listaSeleccionData: [...state.listaSeleccionData, payload]
            }
        }
        case CATALOGOS_NEW_LISTA_SELECCION: {
            return {
                ...state,
            }
        }
        default:
            return state;
    }
}

export default CatalogosReducer;