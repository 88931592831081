// @flow
import {
    CHANGE_LAYOUT,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_PRELOADER,
    CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_THEME_IMAGE,
    CHANGE_SIDEBAR_TYPE,
    CHANGE_TOPBAR_THEME, SET_IS_AUTHENTICATED,
    SET_IS_LOAD_CATALOGOS, SET_NAVBARBUTTONVISIBLE,
    SHOW_DRAWEL_HELP,
    SHOW_RIGHT_SIDEBAR,
    SHOW_SIDEBAR,
    TOGGLE_LEFTMENU,
} from "./actionTypes"

//constants
import {
    layoutTypes,
    layoutWidthTypes,
    leftBarThemeImageTypes,
    leftSideBarThemeTypes,
    leftSidebarTypes,
    topBarThemeTypes,
} from "../../constants/layout";
import {Action} from "../../interfaces/general";

const INIT_STATE = {
    layoutType: layoutTypes.HORIZONTAL,
    layoutWidth: layoutWidthTypes.FLUID,
    leftSideBarTheme: leftSideBarThemeTypes.DARK,
    leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
    leftSideBarType: leftSidebarTypes.DEFAULT,
    topbarTheme: topBarThemeTypes.DARK,
    isPreloader: false,
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
    dataAsignacion: [],
    showDrawelHelp: false,
    isAuthenticated: false,
    isLoadCatalogos: false,
    navbarButtonVisible: false,
}

const action: Action<any> = {
    type: '',
    payload: ''
}

const LayoutReducer = (state = INIT_STATE, {type, payload} = action) => {
    switch (type) {
        case CHANGE_LAYOUT:
            return {
                ...state,
                layoutType: payload,
            }
        case CHANGE_PRELOADER:
            return {
                ...state,
                isPreloader: payload,
            }

        case CHANGE_LAYOUT_WIDTH:
            return {
                ...state,
                layoutWidth: payload,
            }
        case CHANGE_SIDEBAR_THEME:
            return {
                ...state,
                leftSideBarTheme: payload,
            }
        case CHANGE_SIDEBAR_THEME_IMAGE:
            return {
                ...state,
                leftSideBarThemeImage: payload,
            }
        case CHANGE_SIDEBAR_TYPE:
            return {
                ...state,
                leftSideBarType: payload.sidebarType,
            }
        case CHANGE_TOPBAR_THEME:
            return {
                ...state,
                topbarTheme: payload,
            }
        case SHOW_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: payload,
            }
        case SHOW_SIDEBAR:
            return {
                ...state,
                showSidebar: payload,
            }
        case TOGGLE_LEFTMENU:
            return {
                ...state,
                leftMenu: payload,
            }

        case SHOW_DRAWEL_HELP:
            return {
                ...state,
                showDrawelHelp: payload,
            }
        case SET_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload,
            }
        case SET_IS_LOAD_CATALOGOS:
            return {
                ...state,
                isLoadCatalogos: payload,
            }
        case SET_NAVBARBUTTONVISIBLE:
            return {
                ...state,
                navbarButtonVisible: payload
            }
        default:
            return state
    }
}

export default LayoutReducer
