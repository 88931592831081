import {AppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "./AppInsights";
import {Provider} from 'react-redux';
import {msalConfig} from "./authConfig";
import {EventType, PublicClientApplication} from "@azure/msal-browser";
import App from './App';
import store from "./store";
import {BrowserRouter} from 'react-router-dom';
import {render} from 'react-dom';
import {syncActividadWithOtherTabs} from './store/reservasStore/actividadesReservas/middleware/broadcastChanel';

const version = process.env.REACT_APP_VERSION;
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
        // @ts-ignore
        msalInstance.setActiveAccount(event.payload.account);
    }
});

const style = `background: #222; color:${process.env.REACT_APP_PRODUCTION === 'true' ? '#3FCF59' : '#D9A42f'}; font-size:24px`;

if (process.env.REACT_APP_PRODUCTION === 'true') {
    console.log(`%c PROD`, style);
} else {
    console.log(`%c DEV`, style);
}
console.log(`%c Versión: ${version}`, style);

// Sincronización de actividades con otras pestañass
syncActividadWithOtherTabs(store);

const app = (
    <BrowserRouter>
        <AppInsightsContext.Provider value={reactPlugin}>
            <Provider store={store}>
                <App msalInstance={msalInstance}/>
            </Provider>
        </AppInsightsContext.Provider>
    </BrowserRouter>
)

render(app, document.getElementById('root'));
