import { Spinner } from "reactstrap";

interface Props {
  message?: string;
}
export const LoadingSmall = ({ message }: Props) => {
  return (
    <div className="d-flex flex-row">
      <Spinner className="ms-2" color="info" />
      <div style={{ marginLeft: 20 }}>{message}</div>
    </div>
  );
};
