export enum ROLE {
    AdministradorNegocio = 'AdministradorNegocio',
    AdministradorSistema = 'AdministradorSistema',
    AsignacionPasajeroCabina = 'AsignacionPasajeroCabina',
    Comercial = 'Comercial',
    Finanzas = 'Finanzas',
    GerenciaComercial = 'GerenciaComercial',
    GerenciaReservas = 'GerenciaReservas',
    Operaciones = 'Operaciones',
    OperacionesMaritimas = 'OperacionesMaritimas',
    PMTS = 'PMTS',
    Reservas = 'Reservas',
    YieldManagement = 'YieldManagement',
}
