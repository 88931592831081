export const BOOKING_DETAIL_SET_SELECT = "BD_SET_SELECT";
export const BOOKING_DETAIL_SET_COMENTARIOS = "BD_SET_COMENTARIOS";
export const BOOKING_DETAIL_CLEAR = "BD_CLEAR";
export const BOOKING_DETAIL_SET_PASAJEROS = "BD_SET_PAX";
export const BOOKING_DETAIL_SET_PICKUP_DROPOFFS = "BD_SET_PICKUP_DROPOFSS";
export const BOOKING_DETAIL_SET_PRODUCT_COMPONENTS = "BD_SET_PRODUCT_COMPONENTS";
export const BOOKING_DETAIL_SET_OPEN_FORM = "BD_SET_OPEN_FORM";
export const BOOKING_DETAIL_SET_SELECT_COMMENT = "BD_SET_SELECT_COMMENT";
export const BOOKING_DETAIL_IS_EDIT_COMMENT = "BD_IS_EDIT_COMMENT";
export const BOOKING_DETAIL_IS_NOTA_CREDITO = "BD_IS_NOTA_CREDITO";
export const BOOKING_DETAIL_SET_PRODUCTOS_BOOKING = "BD_IS_SET_PRODUCTOS_BOOKING";
export const BOOKING_DETAIL_SET_PRODUCTOS_NOTA_CREDITO = "BD_IS_SET_PRODUCTOS_NOTA_CREDITO";
export const BOOKING_DETAIL_GENERAR_NOTA_CREDITO = "BD_GENERAR_NOTA_CREDITO";