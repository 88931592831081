export const RESERVAS_DETALLES_GET = "RESERVAS_DETALLES_GET";
export const RESERVAS_DETALLES_SET = "RESERVAS_DETALLES_SET";
export const RESERVAS_DETALLES_CLEAR = "RESERVAS_DETALLES_CLEAR";
export const RESERVAS_DETALLES_LOADING = "RESERVAS_DETALLES_LOADING";

export const RESERVAS_DETALLES_DENY_PROCESS = "RESERVAS_DETALLES_DENY_PROCESS";

export const RESERVAS_DETALLES_LETTER_PROCESS = "RESERVAS_DETALLES_LETTER_PROCESS";

export const RESERVAS_DETALLES_MULTI_LETTER_PROCESS = "RESERVAS_DETALLES_MULTI_LETTER_PROCESS";

export const RESERVAS_DETALLES_CONFIRM_PROCESS = "RESERVAS_DETALLES_CONFIRM_PROCESS";
export const RESERVAS_DETALLES_CONFIRM_LOADING = "RESERVAS_DETALLES_CONFIRM_LOADING";

export const RESERVAS_DETALLES_CHANGE_ESTADO_SET = "RESERVAS_DETALLES_CHANGE_ESTADO_SET";
export const RESERVAS_DETALLES_CHANGE_ESTADO_UPDATE = "RESERVAS_DETALLES_CHANGE_ESTADO_UPDATE";
export const RESERVAS_DETALLES_CHANGE_ESTADO_DELETE = "RESERVAS_DETALLES_CHANGE_ESTADO_DELETE";

export const RESERVAS_DETALLES_SET_SELECTED = "RESERVAS_DETALLES_SELECTED_SET";


export const RESERVAS_DETALLES_SET_COMENTARIO = "RESERVAS_DETALLES_SET_COMENTARIO";
export const RESERVAS_DETALLES_UPDATE_COMENTARIO = "RESERVAS_DETALLES_UPDATE_COMENTARIO";

export const RESERVAS_DETALLES_CONFIRM_ALL = "RESERVAS_DETALLES_CONFIRM_ALL";
