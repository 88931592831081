import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Collapse } from "reactstrap";
import NavegacionReportes from "./NavegacionReportes";
import menuSistema from "../../constants/menu.json";
import { tieneAccesos } from "../../helpers/utils";
import { toggleLeftmenu } from "../../store/actions";
import { useMsal } from "@azure/msal-react";
import { SET_IS_AUTHENTICATED } from "../../store/layout/actionTypes";
import { RootState } from "../../store";

export const Navbar = (props: any) => {
  const dispatch = useDispatch();
  const [isOpenReportes, setIsOpenReportes] = useState(false);
  const [extra, setExtra] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const systemMenu = menuSistema;
  const entornoPROD = process.env.REACT_APP_PRODUCTION === "true";
  const location = useLocation();

  const accounts = useMsal().accounts;
  const roles = accounts[0]?.idTokenClaims?.roles ?? [];

  const { navbarButtonVisible } = useSelector(
    (state: RootState) => state.Layout
  );

  useEffect(() => {
    setIsMobile(isMobileDevice());
    let matchingMenuItem = null;
    let ul: any = document.getElementById("navigation");
    let items = ul.getElementsByTagName("a");

    for (let item of items) {
      if (location.pathname === item.pathname) {
        matchingMenuItem = item;
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

    dispatch({ type: SET_IS_AUTHENTICATED, payload: true });
  }, [dispatch]);

  const toggleDropdown = (index: any) => {
    setDropdownOpen((prevOpen) => {
      // Fijar todas las claves a false
      const resetState = Object.keys(prevOpen).reduce(
        (result, key) => {
          // @ts-ignore
          result[key] = false;
          return result;
        },
        {} as typeof prevOpen
      );
      // Activar la clave que se acaba de hacer clic
      // @ts-ignore
      resetState[index] = !prevOpen[index];

      // Cierra la opción de Reportes si otra opción es activada
      if (isOpenReportes) {
        setIsOpenReportes(false);
      }
      return resetState;
    });
  };

  const onClickMenu = (index: any) => {
    if (navbarButtonVisible) {
      toggleDropdown(index);
    }
  };

  const onClicSubMenu = (index: any) => {
    if (navbarButtonVisible) {
      dispatch(toggleLeftmenu(!props.leftMenu));
    }
    setDropdownOpen((prevOpen) => {
      const newState = { ...prevOpen };
      // @ts-ignore
      newState[index] = false; // Cerrar el menú desplegable actual
      return newState;
    });
  };

  function activateParentDropdown(item: any) {
    item.classList.add("active");
  }

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /Mobi/i.test(userAgent);
  };

  return (
    <div
      className={`topnav ${entornoPROD && !navbarButtonVisible ? "navProd" : "navDev"} position-relative`}
    >
      <div className="container-fluid">
        <nav
          className="navbar navbar-light navbar-expand-lg topnav-menu"
          id="navigation"
        >
          <Collapse
            isOpen={props.leftMenu}
            className="navbar-collapse"
            id="topnav-menu-content"
          >
            <ul className="navbar-nav">
              {!isMobile &&
                systemMenu.map((itemMenu: any, index: number) => {
                  if (tieneAccesos(roles, itemMenu.accesos)) {
                    // @ts-ignore
                    return (
                      <li
                        className="nav-item dropdown"
                        key={`menu_` + itemMenu.titulo}
                      >
                        <a
                          className="nav-link arrow-none"
                          id="navbarDropdown"
                          role="button"
                          tabIndex={0}
                          onClick={() => onClickMenu(index)}
                          aria-haspopup="true"
                          // @ts-ignore
                          aria-expanded={dropdownOpen[index] || false}
                        >
                          <i className={itemMenu.icono} />
                          <span style={{ fontSize: 13 }}>
                            {itemMenu.titulo}
                          </span>
                          <div className="arrow-down" />
                        </a>
                        <div
                          // @ts-ignore
                          className={`dropdown-menu ${dropdownOpen[index] ? "show" : ""}`}
                          aria-labelledby="navbarDropdown"
                        >
                          {itemMenu.subMenu.map((subMenu: any) => (
                            <NavLink
                              onClick={() => onClicSubMenu(index)}
                              className="dropdown-item"
                              to={subMenu.to}
                              key={`sub` + subMenu.titulo}
                            >
                              <i className={subMenu.icono} />
                              {subMenu.titulo}
                            </NavLink>
                          ))}
                        </div>
                      </li>
                    );
                  }
                  return null;
                })}
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="/999"
                  onClick={(e) => {
                    e.preventDefault();
                    setExtra(!extra);
                    if (navbarButtonVisible) {
                      setIsOpenReportes(!isOpenReportes);
                      toggleDropdown("reportes"); // Llamamos a toggleDropdown aquí
                    }
                  }}
                >
                  <i className="bx bx-file me-2" />
                  <span style={{ fontSize: 13 }}>Reportes</span>{" "}
                  <div className="arrow-down" />
                </NavLink>
                <NavegacionReportes
                  roles={roles}
                  isOpen={isOpenReportes}
                  leftMenu={props.leftMenu}
                  setIsOpenReportes={setIsOpenReportes}
                />
              </li>
            </ul>
          </Collapse>
        </nav>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
};

const mapStateToProps = (state: any) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default connect(mapStateToProps, {})(Navbar);
