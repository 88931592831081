import { Suspense, useEffect } from "react";
import {
  MsalProvider,
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import "./assets/scss/theme.scss";
import "@progress/kendo-theme-default/dist/all.css";
import "./assets/css/Perseverance.css";
import "./assets/css/Embarcaciones.scss";
import {
  IntlProvider,
  load,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import ecNumbers from "cldr-numbers-full/main/es-EC/numbers.json";
import ecLocalCurrency from "cldr-numbers-full/main/es-EC/currencies.json";
import ecCaGregorian from "cldr-dates-full/main/es-EC/ca-gregorian.json";
import ecDateFields from "cldr-dates-full/main/es-EC/dateFields.json";
import esMessages from "./translations/es/global.json";
import { Loading } from "./common/Loading";

import { notifications } from "./helpers/notifications";
import { UnauthenticatedRoutes } from "./routes/UnauthenticatedRoutes";
import { AuthenticatedRoutes } from "./routes/AuthenticatedRoutes";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

loadMessages(esMessages, "es-EC");
load(
  likelySubtags,
  currencyData,
  weekData,
  ecNumbers,
  ecLocalCurrency,
  ecCaGregorian,
  ecDateFields
);

function App({ msalInstance }: any) {
  const { instance } = useMsal();
  const isAutenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAutenticated) {
      instance
        .ssoSilent({
          scopes: ["user.read"],
          loginHint: "",
        })
        .then((response) => {
          instance.setActiveAccount(response.account);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .loginRedirect({
                scopes: ["user.read"],
              })
              .catch((error) => {
                notifications({ ex: error });
              });
          }
        });
    }
  }, [isAutenticated]);

  return (
    <LocalizationProvider language="es-EC">
      <IntlProvider locale="es-EC">
        <Suspense
          fallback={
            <div id="preloader">
              <Loading />
            </div>
          }
        >
          <MsalProvider instance={msalInstance}>
            <UnauthenticatedTemplate>
              <UnauthenticatedRoutes />
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
              <AuthenticatedRoutes />
            </AuthenticatedTemplate>
          </MsalProvider>
        </Suspense>
      </IntlProvider>
    </LocalizationProvider>
  );
}

export default App;
