import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { accesoRolAndAdmin } from "../helpers/utilsRol";

export const useCurrentAccount = () => {

    const { instance } = useMsal();

    const [userName, setUserName] = useState<string>('');
    const [userRoles, setUserRoles] = useState<string[] | undefined>([]);
    const [userEmail, setUserEmail] = useState<string>('');

    useEffect(() => {

        const currentAccount = instance.getActiveAccount();
        if (currentAccount) {
            setUserName(currentAccount.name!);
            setUserRoles(currentAccount?.idTokenClaims?.roles);
            setUserEmail(currentAccount.username);
        }

    }, [instance])

    const accesoPara = (rol: string): boolean => {

        return accesoRolAndAdmin(rol, userRoles!);
    }

    return { userName,userEmail, userRoles, accesoPara };
}