export const DECKPLAN_LOAD_DATA = "DECKPLAN_LOAD_DATA";
export const DECKPLAN_SET_PASAJEROS_LISTA = "DECKPLAN_SET_PASAJEROS_LISTA";
export const DECKPLAN_GET_PASAJEROS_LISTA = "DECKPLAN_GET_PASAJEROS_LISTA";
export const DECKPLAN_SET_CABINAS = "DECKPLAN_SET_CABINAS";
export const DECKPLAN_SET_VISIBLE = "DECKPLAN_SET_VISIBLE";
export const DECKPLAN_OPEN_FORM_LOCK = "DECKPLAN_OPEN_FORM_LOCK";
export const DECKPLAN_SET_NUMERO_TIPO_CABINA = "DECKPLAN_SET_NUMERO_TIPO_CABINA";
export const DECKPLAN_SET_DATA_SALIDA = "DECKPLAN_SET_DATA_SALIDA";
export const DECKPLAN_SET_PASAJEROS_ASIGNADOS = "DECKPLAN_SET_PASAJEROS_ASIGNADOS";
export const DECKPLAN_SET_MODAL_YIELD = "DECKPLAN_SET_MODAL_YIELD";
export const DECKPLAN_SEND_YIELD_NOTIFICATION = "DECKPLAN_SEND_YIELD_NOTIFICATION";
export const DECKPLAN_DESBLOQUEAR_CABINA = "DECKPLAN_DESBLOQUEAR_CABINA";
export const DECKPLAN_SET_BLOQUEOS = "DECKPLAN_SET_BLOQUEOS";
export const DECKPLAN_SET_DATA_MODAL_CABINA = "DECKPLAN_SET_DATA_MODAL_CABINA";
export const DECKPLAN_ABRIR_CABINA = "DECKPLAN_ABRIR_CABINA";
export const DECKPLAN_SET_DATA_ASIGNAR = "DECKPLAN_SET_DATA_ASIGNAR";
export const DECKPLAN_ASIGNAR_CABINA = "DECKPLAN_ASIGNAR_CABINA";
export const DECKPLAN_SET_DATA_ACOMODACIONES = "DECKPLAN_SET_DATA_ACOMODACIONES";
export const DECKPLAN_SET_MODAL_DESASIGNAR_TODO = "DECKPLAN_SET_MODAL_DESASIGNAR_TODO";
export const DECKPLAN_SET_MODAL_CABINAS_RESTANTES = "DECKPLAN_SET_MODAL_CABINAS_RESTANTES";
export const DECKPLAN_SET_LOADING = "DECKPLAN_SET_LOADING";
export const DECKPLAN_SET_DISPONIBILIDAD = "DECKPLAN_SET_DISPONIBILIDAD";