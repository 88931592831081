export const messageFormartError = (error: any) => {
    if (error.response === undefined) {
        return error;
    }
    if (error.response.status < 500) { // 4XX
        if (error.response.data.detail.includes('Response status code does not indicate success: 400 (Bad Request). ')) {
            return JSON.parse(error.response.data.detail.replace('Response status code does not indicate success: 400 (Bad Request). ', '')).detail
        } else {

            return error.response.data?.detail || error.response.data.error?.message || error;
        }
    } else { // 5XX
        return 'Espere un momento y vuelva a intentar. Si el problema persiste contacte a TI a través de la mesa de ayuda.';
    }
};