import { Action } from "../../../interfaces/general";
import {
    LOADING_HISTORICO_RESERVAS,
    LOADING_PROVEEDORES_RESERVAS,
    SET_ORDEN_PEDIDO,
    SET_PROVEEDORES_RESERVAS,
    SET_PROVEEDOR_SELECT,
    SET_HISTORY_RESERVAS,
    SET_DATASTATE,
    CLEAR_FILTROS_RESERVAS
} from "./type";
import { State } from "@progress/kendo-data-query";
import { TAKE } from "../../../constants/default";

export interface ItemProveedor {
    proveedorId: number;
    nombreCompleto: string;
}

export interface StateReservasHistorico {
    loading: boolean;
    loadProveedores: boolean;
    proveedores: ItemProveedor[];
    proveedorSelect: ItemProveedor;
    ordenPedido: number;
    data: any[];
    dataState: State;
}

export const initialBookingState: State = {
    take: TAKE,
    skip: 0,
}

const INITIAL_STATE: StateReservasHistorico = {
    loading: false,
    loadProveedores: false,
    data: [],
    proveedores: [],
    proveedorSelect: {} as ItemProveedor,
    ordenPedido: 0,
    dataState: initialBookingState,
}

const action: Action<any> = {
    type: '',
    payload: ''
}
const ReservasHistorico = (state = INITIAL_STATE, { type, payload } = action) => {
    switch (type) {
        case LOADING_HISTORICO_RESERVAS:
            return {
                ...state,
                loading: payload
            }
        case LOADING_PROVEEDORES_RESERVAS:
            return {
                ...state,
                loadProveedores: payload
            }
        case SET_PROVEEDORES_RESERVAS:
            return {
                ...state,
                proveedores: payload,
            }
        case SET_PROVEEDOR_SELECT:
            return {
                ...state,
                proveedorSelect: payload
            }
        case SET_ORDEN_PEDIDO:
            return {
                ...state,
                ordenPedido: payload
            }
        case SET_HISTORY_RESERVAS:
            return {
                ...state,
                data: payload
            }
        case SET_DATASTATE:
            return {
                ...state,
                dataState: payload
            }
        case CLEAR_FILTROS_RESERVAS:
            return {
                ...state,
                data: [],
            }
        default:
            return state;
    }
}

export default ReservasHistorico;