import axios from "axios";
import { AuthHeader } from "./authHeader";
import { notifications } from "./notifications";

const API_URL = process.env.REACT_APP_BACKEND;

const axiosApi = axios.create({
    baseURL: API_URL,

})

// Set the AUTH token for any request
axiosApi.interceptors.request.use(async config => {
    config.headers = { Authorization: `Bearer ${(await AuthHeader()).accessToken}` }
    return config;
});

export async function get(url: string) {
    return axiosApi.get(url).then(response => response.data)
}

export async function post(url: string, data: Object) {
    return axiosApi.post(url, { ...data }).then(response => response.data)
}

export async function postJSON(url: string, data: Object) {
    const headers = { "Content-Type": "aplication/json" }
    return axiosApi.post(url, data, { headers }).then(response => response.data)
}

export async function postUpload(url: string, data: any) {
    const headers = { "Content-Type": "multipart/form-data" }
    try {
        return await axiosApi.post(url, data, { headers: headers }).then(response => response.data)
    } catch (ex) {
        notifications({ex});
    }
}

export async function postSalidas(url: string) {
    return axiosApi.post(url).then(response => response.data)
}

export async function put(url: string, data: Object) {
    return axiosApi.put(url, { ...data }).then(response => response.data)
}

export async function del(url: string) {
    return axiosApi.delete(url).then(response => response.data)
}

export async function putUpload(url: string, data: any) {
    const headers = { "Content-Type": "multipart/form-data" }
    try {
        return await axiosApi.put(url, data, { headers: headers }).then(response => response.data)
    } catch (ex) {
        notifications({ex});
    }
}