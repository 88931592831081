import { Reserva } from "../../../interfaces/reserva";
import {
    RESERVAS_OPERACIONES_ABRIR_LISTA_PASAJEROS,
    RESERVAS_OPERACIONES_CLEAR,
    RESERVAS_OPERACIONES_LOADING,
    RESERVAS_OPERACIONES_SET_DATA, RESERVAS_OPERACIONES_SET_FILTRO_RESERVADO,
    RESERVAS_OPERACIONES_SET_SUCESORES,
    RESERVAS_OPERACIONES_START_STOP_PROCESS,
    RESERVAS_OPERACIONES_UPDATE_TOTALES,

} from './types';
import { SucesorValido } from '../../../interfaces/respuestas';
import { FiltroReservado } from "./ReservasOperaciones";

export const loadingRO = (loading: boolean) => ({
    type: RESERVAS_OPERACIONES_LOADING,
    payload: loading
});


export const reservasOperacionStarProcess = (isProcess: boolean) => ({
    type: RESERVAS_OPERACIONES_START_STOP_PROCESS,
    payload: isProcess
});

export const reservasOperacionesSetData = (data: Reserva[]) => ({
    type: RESERVAS_OPERACIONES_SET_DATA,
    payload: data
});

export const reservasOperacionesClear = () => ({
    type: RESERVAS_OPERACIONES_CLEAR
});

export const reservasOperacionesTotalesEstadosUpdate = (pendientes?: number, cp?: number) => ({
    type: RESERVAS_OPERACIONES_UPDATE_TOTALES,
    payload: { pendientes, cp }
});

export const reservasOperacionesSetSucesores = (sucesores: SucesorValido[]) => ({
    type: RESERVAS_OPERACIONES_SET_SUCESORES,
    payload: sucesores
});

export const reservasOperacionesAbrirListaPasajeros = (item: boolean) => ({
    type: RESERVAS_OPERACIONES_ABRIR_LISTA_PASAJEROS,
    payload: item
});

export const setFiltroReservado = (item: FiltroReservado) => ({
    type: RESERVAS_OPERACIONES_SET_FILTRO_RESERVADO,
    payload: item
});