import {
    BOOKING_SET_CURRENT_BOOKING,
    BOOKING_SET_DATA,
    BOOKING_SET_DATASTATE, BOOKING_SET_LOADING, BOOKING_SET_MODAL_CONFIRMACION,
    BOOKING_SET_MODAL_FACTURACION, BOOKING_SET_MODAL_NOTA_CREDITO
} from "./actionsTypes";
import { DataResult, State } from "@progress/kendo-data-query";
import { Booking, IOpenModalConfirmar } from "../../../interfaces/booking";

export const setDataState = (state: State) => ({
    type: BOOKING_SET_DATASTATE,
    payload: state
});

export const setCurrentBooking = (booking: Booking) => ({
    type: BOOKING_SET_CURRENT_BOOKING,
    payload: booking
});

export const setData = (data: DataResult) => ({
    type: BOOKING_SET_DATA,
    payload: data
});

export const setModalFacturacion = (open: boolean) => ({
    type: BOOKING_SET_MODAL_FACTURACION,
    payload: open
});

export const setModalNotaCredito = (open: boolean) => ({
    type: BOOKING_SET_MODAL_NOTA_CREDITO,
    payload: open
});

export const setLoading = (open: boolean) => ({
    type: BOOKING_SET_LOADING,
    payload: open
});

export const setModalConfirmacion = (modalConfirmar: IOpenModalConfirmar) => ({
    type: BOOKING_SET_MODAL_CONFIRMACION,
    payload: modalConfirmar
});