interface Props {
  letters: string[];
}
const LettersColumn = ({ letters }: Props) => (
  <div className="column">
    {letters.map((letter) => (
      <div className="letter-container" key={letter}>
        <span className="letter">{letter}</span>
      </div>
    ))}
  </div>
);

export const Creditos = () => {
  const dev1 =           ["E","F","R","A","I","N"," ","H","E","R","N","A","N","D","E","Z"," ","D","O","N"," ","E","F","R","A"," "," ","D","E","V"," "];
  const dev2 =           ["D","A","N","I","E","L"," ","D","O","M","I","N","G","U","E","Z"," ","D","A","N","Y","2"," ","2","0","2","2"," ","D","E","V"];
  const dev3 =           ["S","T","A","L","I","N"," ","C","H","I","G","U","A","N","O"," ","S","T","A","L","I","N"," ","D","E","V"," ","2","0","2","2"];
  const arquitecto =     ["P","A","U","L"," ","T","O","R","R","E","S"," "," ","P","O","L"," ","A","R","C","H","I","T","E","C","T"," ","2","0","2","2"];
  const tester =         ["G","A","B","Y"," "," ","V","E","N","E","G","A","S"," ","G","A","B","Y"," "," ","T","E","S","T","E","R"," ","2","0","2","2"];
  const jefeArquitecto = ["D","A","R","I","O"," ","L","E","O","N"," ","D","A","R","I"," ","C","H","I","E","F"," ","A","R","C","H","I","T","E","C","T"];
  const otros =          ["D","A","V","I","D","/","D","A","N","I","E","L","M","E","T","R","O","P","O","L","I","T","A","N","T","O","U","R","I","N","G"];
  return (
    <>
      <h1>Creditos a:</h1>
      <div className="matrix">
      <LettersColumn letters={dev1} />
      <LettersColumn letters={dev2} />
      <LettersColumn letters={dev3} />
      <LettersColumn letters={arquitecto} />
      <LettersColumn letters={tester} />
      <LettersColumn letters={jefeArquitecto} />
      <LettersColumn letters={otros} />
      <LettersColumn letters={dev3} />
      <LettersColumn letters={dev2} />
      <LettersColumn letters={dev1} />
      <LettersColumn letters={jefeArquitecto} />
      <LettersColumn letters={tester} />
      <LettersColumn letters={arquitecto} />
      <LettersColumn letters={otros} />
      <LettersColumn letters={dev2} />
      <LettersColumn letters={dev3} />
      <LettersColumn letters={dev1} />
      <LettersColumn letters={tester} />
      <LettersColumn letters={jefeArquitecto} />
      <LettersColumn letters={arquitecto} />
      <LettersColumn letters={otros} />
      <LettersColumn letters={jefeArquitecto} />
      <LettersColumn letters={dev3} />
      <LettersColumn letters={dev2} />
      <LettersColumn letters={dev1} />
      <LettersColumn letters={tester} />
      <LettersColumn letters={arquitecto} />
      <LettersColumn letters={otros} />
      <LettersColumn letters={arquitecto} />
      <LettersColumn letters={dev3} />
      <LettersColumn letters={dev2} />
      <LettersColumn letters={dev1} />
      <LettersColumn letters={jefeArquitecto} />
      <LettersColumn letters={tester} />
      <LettersColumn letters={otros} />
      <LettersColumn letters={tester} />
      <LettersColumn letters={dev3} />
      <LettersColumn letters={dev2} />
      <LettersColumn letters={dev1} />
      <LettersColumn letters={jefeArquitecto} />
      <LettersColumn letters={arquitecto} />
      <LettersColumn letters={otros} />
      <LettersColumn letters={dev1} />
      <LettersColumn letters={tester} />
      <LettersColumn letters={arquitecto} />
      <LettersColumn letters={jefeArquitecto} />
    </div>
    </>
  );
};
