import { all, fork } from 'redux-saga/effects';

import LayoutSaga from './layout/LayoutSaga';
import BookingDetailSaga from './bookingStore/bookingDetail/bookingDetailSaga';
import reservasDetallesSaga from './reservasStore/reservasDetalles/reservasDetallesSagas';
import actividadReservaDetalleSaga from './reservasStore/actividadReservaDetalles/actividadReservaDetallesSaga';
import actividadReservaSagas from './reservasStore/actividadesReservas/actividadesReservasSagas';
import reservasOperacionesSagas from './reservasStore/reservas/reservasOperacionesSagas';
import reservasActionsSagas from './reservasStore/reservasActions/reservasActionsSagas';
import CatalogosSaga from './catalogos/CatalogosSaga';
import deckplanSaga from './deckplan/deckplanSaga';
import bookingSaga from './bookingStore/booking/bookingSaga';
import reservasHistoricoSaga from './reservasStore/reservasHistorico/ReservasHistoricoSagas';
import catalogoListaSeleccion from './catalogos/CatalogoListaSeleccionSagas';

export default function* rootSaga() {
    yield all([
        fork(LayoutSaga),
        fork(BookingDetailSaga),
        fork(actividadReservaSagas),
        fork(actividadReservaDetalleSaga),
        fork(reservasOperacionesSagas),
        fork(reservasDetallesSaga),
        fork(reservasActionsSagas),
        fork(CatalogosSaga),
        fork(deckplanSaga),
        fork(bookingSaga),
        fork(reservasHistoricoSaga),
        fork(catalogoListaSeleccion),
    ])
}