import { BookingDetail, StateBookingDetail } from "../../../interfaces/detailBooking";
import { Action } from "../../../interfaces/general";
import {
    BOOKING_DETAIL_CLEAR, BOOKING_DETAIL_IS_EDIT_COMMENT, BOOKING_DETAIL_IS_NOTA_CREDITO,
    BOOKING_DETAIL_SET_COMENTARIOS, BOOKING_DETAIL_SET_OPEN_FORM,
    BOOKING_DETAIL_SET_PASAJEROS,
    BOOKING_DETAIL_SET_PICKUP_DROPOFFS, BOOKING_DETAIL_SET_PRODUCTOS_BOOKING, BOOKING_DETAIL_SET_PRODUCTOS_NOTA_CREDITO,
    BOOKING_DETAIL_SET_SELECT, BOOKING_DETAIL_SET_SELECT_COMMENT, BOOKING_DETAIL_SET_PRODUCT_COMPONENTS
} from './actionsTypes';
import { Comentario } from "../../../interfaces/comentario";

const INIT_STATE: StateBookingDetail = {
    detailSelected: {} as BookingDetail,
    comentarios: [],
    pasajeros: [],
    pickupDrop: [],
    detalleLegolands: [],
    openForm: false,
    selectComment: {} as Comentario,
    isEditComment: false,
    isNotaCredito: false,
    productosBooking: [],
    productosNotaCredito: [],
}
const action: Action<any> = {
    type: '',
    payload: ''
}
export const BookingDetailReduce = (state = INIT_STATE, { type, payload } = action): StateBookingDetail => {
    switch (type) {
        case BOOKING_DETAIL_SET_SELECT:
            return {
                ...state,
                detailSelected: payload,
                selectComment: {} as Comentario,
            }
        case BOOKING_DETAIL_SET_COMENTARIOS:
            return {
                ...state,
                comentarios: payload
            }
        case BOOKING_DETAIL_CLEAR:
            return {
                ...state,
                comentarios: [],
                pasajeros: [],
                pickupDrop: [],
                detalleLegolands: [],
                selectComment: {} as Comentario,
                detailSelected: {} as BookingDetail,
            }
        case BOOKING_DETAIL_SET_PASAJEROS:
            return {
                ...state,
                pasajeros: payload
            }
        case BOOKING_DETAIL_SET_PICKUP_DROPOFFS:
            return {
                ...state,
                pickupDrop: payload
            }
        case BOOKING_DETAIL_SET_OPEN_FORM:
            return {
                ...state,
                openForm: payload
            }
        case BOOKING_DETAIL_SET_SELECT_COMMENT:
            return {
                ...state,
                selectComment: payload
            }
        case BOOKING_DETAIL_IS_EDIT_COMMENT:
            return {
                ...state,
                isEditComment: payload
            }
        case BOOKING_DETAIL_IS_NOTA_CREDITO:
            return {
                ...state,
                isNotaCredito: payload
            }
        case BOOKING_DETAIL_SET_PRODUCTOS_BOOKING:
            return {
                ...state,
                productosBooking: payload
            }
        case BOOKING_DETAIL_SET_PRODUCTOS_NOTA_CREDITO:
            return {
                ...state,
                productosNotaCredito: payload
            }
        case BOOKING_DETAIL_SET_PRODUCT_COMPONENTS:
            return {
                ...state,
                detalleLegolands: payload
            }
        default:
            return {
                ...state
            }
    }
}
export default BookingDetailReduce;